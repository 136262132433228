export enum ActionTypesEnum {
    SUBMIT_FORM = "SUBMIT_FORM",
    SUBMIT_FORM_SUCCESS = "SUBMIT_FORM_SUCCESS",
    SUBMIT_FORM_ERROR = "SUBMIT_FORM_ERROR",

    CHANGE_SITE_FORM_TYPE='CHANGE_SITE_FORM_TYPE',
    CHANGE_SITE_FORM_STATUS='CHANGE_SITE_FORM_STATUS',
    CHANGE_SITE_FORM_URL='CHANGE_SITE_FORM_URL',
    CHANGE_SITE_FORM_SEARCH_DATE='CHANGE_SITE_FORM_SEARCH_DATE',
    CHANGE_SITE_FORM_EVIDENCE_LINK='CHANGE_SITE_FORM_EVIDENCE_LINK',
    CHANGE_SITE_FORM_EVIDENCE_FILES='CHANGE_SITE_FORM_EVIDENCE_FILES',
    CHANGE_SITE_FORM_IS_ACTIVE='CHANGE_SITE_FORM_IS_ACTIVE',
    CHANGE_SITE_FORM='CHANGE_SITE_FORM',

    CHANGE_PROFILE_FORM_TYPE='CHANGE_PROFILE_FORM_TYPE',
    CHANGE_PROFILE_FORM_STATUS='CHANGE_PROFILE_FORM_STATUS',
    CHANGE_PROFILE_FORM_URL='CHANGE_PROFILE_FORM_URL',
    CHANGE_PROFILE_FORM_SEARCH_DATE='CHANGE_PROFILE_FORM_SEARCH_DATE',
    CHANGE_PROFILE_FORM_PROFILE='CHANGE_PROFILE_FORM_PROFILE',
    CHANGE_PROFILE_FORM_PLATFORM='CHANGE_PROFILE_FORM_PLATFORM',
    CHANGE_PROFILE_FORM_EVIDENCE_LINK='CHANGE_PROFILE_FORM_EVIDENCE_LINK',
    CHANGE_PROFILE_FORM_EVIDENCE_FILES='CHANGE_PROFILE_FORM_EVIDENCE_FILES',
    CHANGE_PROFILE_FORM_IS_ACTIVE='CHANGE_PROFILE_FORM_IS_ACTIVE',
    CHANGE_PROFILE_FORM='CHANGE_PROFILE_FORM',

    CHANGE_CONTENT_FORM_TYPE='CHANGE_CONTENT_FORM_TYPE',
    CHANGE_CONTENT_FORM_STATUS='CHANGE_CONTENT_FORM_STATUS',
    CHANGE_CONTENT_FORM_URL='CHANGE_CONTENT_FORM_URL',
    CHANGE_CONTENT_FORM_CONTENT='CHANGE_CONTENT_FORM_CONTENT',
    CHANGE_CONTENT_FORM_PLATFORM='CHANGE_CONTENT_FORM_PLATFORM',
    CHANGE_CONTENT_FORM_EVIDENCE_LINK='CHANGE_CONTENT_FORM_EVIDENCE_LINK',
    CHANGE_CONTENT_FORM_EVIDENCE_FILES='CHANGE_CONTENT_FORM_EVIDENCE_FILES',
    CHANGE_CONTENT_FORM_IS_ACTIVE='CHANGE_CONTENT_FORM_IS_ACTIVE',
    CHANGE_CONTENT_FORM='CHANGE_CONTENT_FORM',

    CHANGE_ADS_FORM_TYPE='CHANGE_ADS_FORM_TYPE',
    CHANGE_ADS_FORM_STATUS='CHANGE_ADS_FORM_STATUS',
    CHANGE_ADS_FORM_START_DATE='CHANGE_ADS_FORM_START_DATE',
    CHANGE_ADS_FORM_SEARCH_DATE='CHANGE_ADS_FORM_SEARCH_DATE',
    CHANGE_ADS_FORM_ID='CHANGE_ADS_FORM_ID',
    CHANGE_ADS_FORM_PLATFORM='CHANGE_ADS_FORM_PLATFORM',
    CHANGE_ADS_FORM_URL='CHANGE_ADS_FORM_URL',
    CHANGE_ADS_FORM_INSTAGRAM_PROFILE='CHANGE_ADS_FORM_INSTAGRAM_PROFILE',
    CHANGE_ADS_FORM_ADVERTISER_NAME='CHANGE_ADS_FORM_ADVERTISER_NAME',
    CHANGE_ADS_FORM_ADVERTISER_ID='CHANGE_ADS_FORM_ADVERTISER_ID',
    CHANGE_ADS_FORM_EVIDENCE_LINK='CHANGE_ADS_FORM_EVIDENCE_LINK',
    CHANGE_ADS_FORM_EVIDENCE_FILES='CHANGE_ADS_FORM_EVIDENCE_FILES',
    CHANGE_ADS_FORM_IS_ACTIVE='CHANGE_ADS_FORM_IS_ACTIVE',
    CHANGE_ADS_FORM='CHANGE_ADS_FORM',

    CHANGE_FILTER_TEXT = "CHANGE_FILTER_TEXT",
    CHANGE_FILTER_TYPE = "CHANGE_FILTER_TYPE",
    CHANGE_FILTER_PLATFORM = "CHANGE_FILTER_PLATFORM",
    CHANGE_STATUS_PLATFORM = "CHANGE_STATUS_PLATFORM",
    CHANGE_FILTER_IPF = "CHANGE_FILTER_IPF",
    CHANGE_FILTER_DATE_RANGE_START = "CHANGE_FILTER_DATE_RANGE_START",
    CHANGE_FILTER_DATE_RANGE_END = "CHANGE_FILTER_DATE_RANGE_END",
    CHANGE_FILTER_DAYS = "CHANGE_FILTER_DAYS",

    CHANGE_LIST = "CHANGE_LIST",
    CHANGE_TABLE = "CHANGE_TABLE",
    CHANGE_CURRENT_IDS = "CHANGE_CURRENT_IDS",
    CHANGE_CURRENT = "CHANGE_CURRENT",

    FETCH_FRAUDS = "FETCH_FRAUDS",

    GET_FRAUDS_BY_COMPANY= "GET_FRAUDS_BY_COMPANY",
    REGISTER_FRAUD = "REGISTER_FRAUD", 
    REGISTER_FRAUD_FAILURE = "REGISTER_FRAUD_FAILURE", 
    UPDATE_FRAUD = "UPDATE_FRAUD", 
    RESTORE_FRAUD = "RESTORE_FRAUD", 
    UPDATE_MULTIPLE_FRAUDS = "UPDATE_MULTIPLE_FRAUDS", 
    RESTORE_MULTIPLE_FRAUDS = "RESTORE_MULTIPLE_FRAUDS", 

    ADD_FRAUD = "ADD_FRAUD", 
    UPDATE_FRAUD_FROM_SAGA = "UPDATE_FRAUD_FROM_SAGA", 
    UPDATE_MULTIPLE_FRAUDS_FROM_SAGA = "UPDATE_MULTIPLE_FRAUDS_FROM_SAGA", 

    CLEAN_FORMS = "CLEAN_FORMS",

    IS_LOADING = "IS_LOADING",

    CHANGE_LAST_LIST = "CHANGE_LAST_LIST",

    CHANGE_FRAUDS_QUANTITY_BY_STATE= "CHANGE_FRAUDS_QUANTITY_BY_STATE",
    CHANGE_FRAUDS_FILTERED_QUANTITY_BY_STATE= "CHANGE_FRAUDS_FILTERED_QUANTITY_BY_STATE",
    CHANGE_FRAUDS_TOTAL_PAGES_BY_STATE= "CHANGE_FRAUDS_TOTAL_PAGES_BY_STATE",

    CHANGE_FORM_ERROR= "CHANGE_FORM_ERROR",
}