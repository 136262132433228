import { AdminInitialState } from "./state";
import { ActionTypesEnum } from "./types.enum";

const adminReducer = (state = AdminInitialState, action: { type: ActionTypesEnum, payload: any }) => {
    switch (action.type) {
        case ActionTypesEnum.FETCH_COMPANIES:
            return {
                ...state,
            };

        case ActionTypesEnum.CHANGE_COMPANIES_LIST:
            return {
                ...state,
                companiesList: action.payload
            };

        case ActionTypesEnum.FETCH_COMPANIES_GROUP:
            return {
                ...state,
            };

        case ActionTypesEnum.CHANGE_COMPANIES_GROUP_LIST:
            return {
                ...state,
                companiesGroupList: action.payload
            };

        case ActionTypesEnum.INVITE_USER:
            return {
                ...state,
            };

        case ActionTypesEnum.CHANGE_USER_PERMISSIONS:
            return {
                ...state,
            };

        case ActionTypesEnum.FETCH_USER_TO_CHANGE:
            return {
                ...state,
            };

        case ActionTypesEnum.CHANGE_USER:
            return {
                ...state,
                user: action.payload
            };

        case ActionTypesEnum.FETCH_USERS:
            return {
                ...state,
            };

        case ActionTypesEnum.CHANGE_USERS:
            return {
                ...state,
                users: action.payload
            };

        default:
            return state;
    }
};
export default adminReducer;