import React, { useMemo, useEffect, useState } from "react";
import TableContainerReactTable from "../../../Components/Common/TableContainerReactTable";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";
import { Badge, Fade } from "reactstrap";
import {
  setAggressorColorByScore,
  setStatusColor,
} from "../../../utils/setColors";
import BouncingDotsLoader from "../../../Components/Common/BoucingDotsLoader";
import { useDispatch, useSelector } from "react-redux";
import { setEvent } from "../../../store/actions";
import { mapCategory, mapPhase } from "../../../utils/mapsTranslate";
import { Check, X, Pause } from "feather-icons-react";
import moment from "moment";
import "moment/locale/pt-br";

const ReportsTable = ({ reportData, reportError, reportLoading }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tableKey, setTableKey] = useState(0);

  const selectProfileState = (state) => state.Profile;
  const { pageSize, selectedCampaign } = useSelector(selectProfileState);

  useEffect(() => {
    setTableKey((prevKey) => prevKey + 1);
  }, [pageSize]);

  const columns = useMemo(
    () => [
      {
        Header: "Agressividade",
        accessor: (cellProps) => cellProps.aggressivenessIndex.toFixed(2),
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <i
                className={classnames(
                  setAggressorColorByScore(value),
                  "mdi mdi-circle align-middle me-2 ms-2"
                )}
              ></i>
              <span className="fw-medium text-black">{value}</span>
            </>
          );
        },
        sortType: "alphanumeric",
      },
      {
        Header: "Agressor",
        accessor: (cellProps) => cellProps.domain,
        Cell: ({ cell: { value } }) => {
          return (
            <span className="text-decoration-underline text-body-secondary">
              {value}
            </span>
          );
        },
      },
      {
        Header: "Canais",
        accessor: (cellProps) => cellProps.channel.join(", "),
        Cell: ({ cell: { value } }) => {
          return <span>{value}</span>;
        },
      },
      {
        Header: "Primeira ocorrência",
        accessor: (cellProps) => {
          const [day, month, year] = cellProps.createdAt.split("/");
          return new Date(year, month - 1, day);
        },
        Cell: ({ cell: { value } }) => {
          return (
            <div className="min-w-100">
              {moment(value).locale("pt-br").format("DD MMM, YYYY")}
            </div>
          );
        },
        sortType: "datetime",
      },
      {
        Header: "Última ocorrência",
        accessor: (cellProps) => {
          const [day, month, year] = cellProps.lastOccursAt.split("/");

          return new Date(year, month - 1, day);
        },
        Cell: ({ cell: { value } }) => {
          return (
            <div className="min-w-100">
              {moment(value).locale("pt-br").format("DD MMM, YYYY")}
            </div>
          );
        },
        sortType: "datetime",
      },
      {
        Header: "Categoria",
        accessor: (cellProps) => cellProps.category,
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <div className="min-w-100">
                {mapCategory[value] || value || "-"}
              </div>
            </>
          );
        },
        sortType: "datetime",
      },
      {
        id: "acting",
        Header: () => {
          return <div className="text-center">Notificar</div>;
        },
        accessor: (cellProps) => cellProps.acting,
        Cell: ({ cell: { value } }) => {
          return (
            <div className="w-100 d-flex justify-content-center">
              {value === "notify" ? (
                <Badge
                  color="success"
                  className="rounded-pill d-flex align-items-center justify-content-center p-1"
                  style={{ width: "18px", height: "18px" }}
                >
                  <Check size={18} />
                </Badge>
              ) : value === "whitelist" ? (
                <Badge
                  color="danger"
                  className="rounded-pill d-flex align-items-center justify-content-center p-1"
                  style={{ width: "18px", height: "18px" }}
                >
                  <X size={18} />
                </Badge>
              ) : value === "standby" ? (
                <Badge
                  color="gray-500"
                  className="rounded-pill d-flex align-items-center justify-content-center p-1"
                  style={{ width: "18px", height: "18px" }}
                >
                  <Pause size={18} />
                </Badge>
              ) : (
                "-"
              )}
            </div>
          );
        },
      },
      {
        id: "status",
        Header: () => {
          return <div className="text-center">Fase Atual</div>;
        },
        accessor: (cellProps) => cellProps.status,
        Cell: ({ cell: { value } }) => {
          const status = mapPhase[value];
          return (
            <>
              <div className="d-flex justify-content-center">
                {status ? (
                  <Badge color={setStatusColor(status)}>{status}</Badge>
                ) : (
                  <span>-</span>
                )}
              </div>
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {reportLoading ? (
        <div
          style={{ minHeight: "50vh" }}
          className="d-flex align-items-center justify-content-center"
        >
          <BouncingDotsLoader />
        </div>
      ) : (
        <Fade>
          <TableContainerReactTable
            key={tableKey}
            columns={columns || []}
            data={reportData || []}
            isPagination={true}
            customPageSize={pageSize}
            className="custom-header-css table align-middle table-nowrap"
            tableClassName="table-centered align-middle table-nowrap mb-0"
            thClass="text-black"
            divClass="table-responsive"
            theadClass="table-light"
            onRowClick={(row) => {
              dispatch(setEvent(row.id));
              navigate(`/ticket-details?ticketId=${row.id}`, {
                state: { aggressivenessIndex: row.aggressivenessIndex },
                replace: true
              });
            }}
          />
        </Fade>
      )}
    </React.Fragment>
  );
};

export default ReportsTable;
