export enum RolesEnum {
    CS = "CS",
    BS = "BS",
    COMMERCIAL = "Comercial",
    OPECA = "Opec",
    MANAGER = "Gerente",
    ADMIN = "Admin",
    VIEW = "Visualização",
    CUSTOMER_VIEW = "Visualização-Cliente",
    CUSTOMER_TRIAGE = "Triagem-Cliente",
    CUSTOMER_ADMIN = "Admin-Cliente",
}