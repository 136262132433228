import { useEffect, useRef } from 'react';
import Select from 'react-select';
import { Row, Col, Label, Fade } from 'reactstrap';
import { getUserCampaigns, setSelectedCampaign } from '../../store/actions';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";

const CampaignSelector = ({ panelLoading, user, selectedCampaign, selectedCompany }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    if(selectedCompany) dispatch(getUserCampaigns(selectedCompany))
  }, [selectedCompany, dispatch])

  const selectCampaign = (selectedOption) => {
    if (selectedOption) searchParams.set('campaignId', selectedOption.value);
    dispatch(setSelectedCampaign(selectedOption))

    navigate({ search: searchParams.toString() });
  }

  return (
    <>
      {user.campaigns?.length > 1 ?
        <Fade>
          <Row className="align-items-center justify-content-end mb-3">
            <Col xs={5} sm={4} className='text-end pe-0'>
              <Label htmlFor="colFormLabelSm" className="col-form-label">Campanha</Label>
            </Col>
            <Select
              className='w-25'
              placeholder={t('global.loading')}
              isDisabled={panelLoading}
              isLoading={panelLoading}
              options={user.campaigns?.map((campaign) => ({
                value: campaign._id,
                label: campaign.name,
                createdAt: campaign.createdAt,
                keywords: campaign.keywords,
                urlLogo: campaign.urlLogo,
                pages: campaign.pages
              }))}
              value={selectedCampaign}
              onChange={(selectedOption) => selectCampaign(selectedOption)}
            />
          </Row>
        </Fade>
        :
        null
      }
    </>
  );
};

export default CampaignSelector;