import { ReactNode, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { dsVariables } from "../variables";
import Label from "./Text/Label";
import { CheckCircleOutline, Close, ErrorOutline, InfoOutlined, WarningOutlined, } from "@mui/icons-material";
import Icon from "./Icon";

type TSnackbarState = 'success' | 'error' | 'info' | 'warning';

interface IProps {
  state?: TSnackbarState;
  children: ReactNode;
  visible?: boolean
  setVisible: any
}

export default function Snackbar({ children, state = 'info', visible = false, setVisible }: IProps) {
  useEffect(() => {
    if (visible) {
      setVisible(true)
      setTimeout(() => {
        setVisible(false);
      }, 10000);
    }
  }, [visible]);


  const getIcon = (state: TSnackbarState) => {
    switch (state) {
      case "success": return <Icon width={16} height={16} IconComponent={CheckCircleOutline} color={dsVariables.colors.Green[600]} />;
      case "error": return <Icon width={16} height={16} IconComponent={ErrorOutline} color={dsVariables.colors.Red[600]} />;
      case "warning": return <Icon width={16} height={16} IconComponent={WarningOutlined} color={dsVariables.colors.Orange[600]} />;
      case "info":
      default:
        return <Icon width={16} height={16} IconComponent={InfoOutlined} color={dsVariables.colors.Blue[600]} />;
    }
  };

  return (
    <Div isVisible={visible} state={state} className="d-flex align-items-center position-fixed justify-content-between">
      <div className="d-flex gap-2 align-items-center">
        {getIcon(state)}
        <Label>{children}</Label>
      </div>
      <CloseButton className="bg-transparent border-0" onClick={() => setVisible(false)}><Icon width={16} height={16} IconComponent={Close} color={dsVariables.colors.Astronaut[900]} /></CloseButton>
    </Div>
  );
}


const Div = styled.div<{ state: TSnackbarState; isVisible: boolean }>`
    padding: ${dsVariables.spacing[16]};
    border-radius: ${dsVariables.cornerRadius[4]};
    gap: ${dsVariables.spacing[16]};
    left: 50%;
    z-index: 99999999999;
    max-width: 491px;
    word-break: break-word;
    border-width:1px;
    border-style:solid;
    overflow: hidden;

    &:after{
      content: '';
      position: absolute;
      width: ${dsVariables.spacing[4]};
      height:100%;
      left: 0;
      bottom: 0;
      background-color: ${({ state }) => {
    switch (state) {
      case "success": return dsVariables.colors.Green[400];
      case "error": return dsVariables.colors.Red[400];
      case "warning": return dsVariables.colors.Orange[400];
      case "info":
      default:
        return dsVariables.colors.Blue[400];
    }
  }};
    }

    background-color: ${({ state }) => {
    switch (state) {
      case "success": return dsVariables.colors.Green[100];
      case "error": return dsVariables.colors.Red[100];
      case "warning": return dsVariables.colors.Orange[100];
      case "info":
      default:
        return dsVariables.colors.Blue[100];
    }
  }};
    color: ${({ state }) => {
    switch (state) {
      case "success": return dsVariables.colors.Green[600];
      case "error": return dsVariables.colors.Red[600];
      case "warning": return dsVariables.colors.Orange[600];
      case "info":
      default:
        return dsVariables.colors.Blue[600];
    }
  }};
    border-color: ${({ state }) => {
    switch (state) {
      case "success": return dsVariables.colors.Green[200];
      case "error": return dsVariables.colors.Red[200];
      case "warning": return dsVariables.colors.Orange[200];
      case "info":
      default:
        return dsVariables.colors.Blue[200];
    }
  }};

    animation: ${({ isVisible }) => isVisible ? fadeInUp : fadeOutDown} 0.7s ease forwards;
`;

const CloseButton = styled.button`
  font-size: ${dsVariables.spacing[16]};
`;

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%,100vh);
  }
  100% {
    opacity: 1;
    transform: translate(-50%,-300px);
  }
`;

const fadeOutDown = keyframes`
  0% {
    opacity: 1;
    transform: translate(-50%,-300px);
  }
  100% {
    opacity: 0;
    transform: translate(-50%,100vh);
  }
`;