import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_CAMPAIGN_ELIMINATED_REPORT,
  GET_CAMPAIGN_MEDIATION_REPORT,
  GET_CAMPAIGN_NEW_REPORT,
  GET_CAMPAIGN_NOTIFIED_REPORT,
  GET_CAMPAIGN_REAPPEARED_REPORT,
  GET_CAMPAIGN_TOTAL_REPORT,
} from "./actionTypes";
import {
  getEliminatedReportByCampaign,
  getMediationReportByCampaign,
  getNewReportByCampaign,
  getNotifiedReportByCampaign,
  getReappearedReportByCampaign,
  getTotalReportByCampaign,
} from "../../../helpers/backend_helper";
import {
  setCampaignEliminatedReport,
  setCampaignEliminatedReportError,
  setCampaignEliminatedReportLoading,
  setCampaignMediationReport,
  setCampaignMediationReportError,
  setCampaignMediationReportLoading,
  setCampaignNewReport,
  setCampaignNewReportError,
  setCampaignNewReportLoading,
  setCampaignNotifiedReport,
  setCampaignNotifiedReportError,
  setCampaignNotifiedReportLoading,
  setCampaignReappearedReport,
  setCampaignReappearedReportError,
  setCampaignReappearedReportLoading,
  setCampaignReport,
  setCampaignReportError,
  setCampaignReportLoading,
  setPositionsFilter,
  setReportCampaignId,
} from "./actions";
import { orderArrayByScoreAndDomain } from "../../../utils/arrayFunctions";

function* getCampaignTotalReport({
  payload: { campaignId, startDate, endDate, page },
}) {
  try {
    yield put(setCampaignReportLoading());
    const response = yield call(getTotalReportByCampaign, {
      campaignId,
      startDate,
      endDate,
      page,
    });
    response.data.sort(orderArrayByScoreAndDomain);
    const positionsPage = [];
    response.data.forEach((item) => {
      item.firstPage.forEach((position) => {
        if (!positionsPage.includes(position)) {
          positionsPage.push(position);
        }
      });

      item.secondPage.forEach((position) => {
        if (!positionsPage.includes(position)) {
          positionsPage.push(position);
        }
      });
    });
    positionsPage.map((item) => parseInt(item, 10));
    positionsPage.sort((a, b) => a - b);
    const positionsLabels = positionsPage.map((position) => ({
      value: position,
      label: `Posição ${position}`,
    }));
    yield put(setPositionsFilter(positionsLabels));
    yield put(setCampaignReport(response.data));
    yield put(setReportCampaignId(campaignId));
  } catch {
    yield put(setCampaignReportError());
  }
}

function* getCampaignNewReport({
  payload: { campaignId, startDate, endDate, page },
}) {
  try {
    yield put(setCampaignNewReportLoading());
    const response = yield call(getNewReportByCampaign, {
      campaignId,
      startDate,
      endDate,
      page,
    });
    response.data.sort(orderArrayByScoreAndDomain);
    yield put(setCampaignNewReport(response.data));
    yield put(setReportCampaignId(campaignId));
  } catch {
    yield put(setCampaignNewReportError());
  }
}

function* getCampaignReappearedReport({
  payload: { campaignId, startDate, endDate, page },
}) {
  try {
    yield put(setCampaignReappearedReportLoading());
    const response = yield call(getReappearedReportByCampaign, {
      campaignId,
      startDate,
      endDate,
      page,
    });
    response.data.sort(orderArrayByScoreAndDomain);
    yield put(setCampaignReappearedReport(response.data));
    yield put(setReportCampaignId(campaignId));
  } catch {
    yield put(setCampaignReappearedReportError());
  }
}

function* getCampaignNotifiedReport({
  payload: { campaignId, startDate, endDate, page },
}) {
  try {
    yield put(setCampaignNotifiedReportLoading());
    const response = yield call(getNotifiedReportByCampaign, {
      campaignId,
      startDate,
      endDate,
      page,
    });
    response.data.sort(orderArrayByScoreAndDomain);
    yield put(setCampaignNotifiedReport(response.data));
    yield put(setReportCampaignId(campaignId));
  } catch {
    yield put(setCampaignNotifiedReportError());
  }
}

function* getCampaignMediationReport({
  payload: { campaignId, startDate, endDate, page },
}) {
  try {
    yield put(setCampaignMediationReportLoading());
    const response = yield call(getMediationReportByCampaign, {
      campaignId,
      startDate,
      endDate,
      page,
    });
    response.data.sort(orderArrayByScoreAndDomain);
    yield put(setCampaignMediationReport(response.data));
    yield put(setReportCampaignId(campaignId));
  } catch {
    yield put(setCampaignMediationReportError());
  }
}

function* getCampaignEliminatedReport({
  payload: { campaignId, startDate, endDate, page },
}) {
  try {
    yield put(setCampaignEliminatedReportLoading());
    const response = yield call(getEliminatedReportByCampaign, {
      campaignId,
      startDate,
      endDate,
      page,
    });
    response.data.sort(orderArrayByScoreAndDomain);
    yield put(setCampaignEliminatedReport(response.data));
    yield put(setReportCampaignId(campaignId));
  } catch {
    yield put(setCampaignEliminatedReportError());
  }
}

export function* reportSaga() {
  yield takeLatest(GET_CAMPAIGN_TOTAL_REPORT, getCampaignTotalReport);
  yield takeLatest(GET_CAMPAIGN_NEW_REPORT, getCampaignNewReport);
  yield takeLatest(GET_CAMPAIGN_REAPPEARED_REPORT, getCampaignReappearedReport);
  yield takeLatest(GET_CAMPAIGN_NOTIFIED_REPORT, getCampaignNotifiedReport);
  yield takeLatest(GET_CAMPAIGN_MEDIATION_REPORT, getCampaignMediationReport);
  yield takeLatest(GET_CAMPAIGN_ELIMINATED_REPORT, getCampaignEliminatedReport);
}

export default reportSaga;
