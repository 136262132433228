import Input from "../../../../DesignSystem/components/Input/Input";
import Label from "../../../../DesignSystem/components/Text/Label";
import { ThreatPlatformEnum } from "../../../../enums/threat-platform.enum";
import Select from "../../../../DesignSystem/components/Select";
import { ThreatStatusActiveEnum } from "../../../../enums/threat-status-active.enum";
import ButtonPrimary from "../../../../DesignSystem/components/Button/ButtonPrimary";
import { CalendarMonth } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/store.interface";
import { changeProfileFormEvidenceFiles, changeProfileFormEvidenceLink, changeProfileFormIsActive, changeProfileFormPlatform, changeProfileFormProfile, changeProfileFormSearchDate, changeProfileFormUrl } from "../../../../store/threats/actions";
import InputEvidence from "../../../../DesignSystem/components/Input/InputEvidence";
import InputDate from "../../../../DesignSystem/components/Input/InputDate";
import { ThreatTypeTextEnum } from "../../../../enums/threat-type-text.enum";
import { dsVariables } from "../../../../DesignSystem/variables";

interface IProps {
    setTypeForm?: any
    isInvalidUrl: boolean
}

export default function ProfileForm({ setTypeForm, isInvalidUrl }: IProps) {
    const dispatch = useDispatch()

    const { profileForm } = useSelector((store: IStore) => store.Threats.forms)

    return (
        <form className="mt-2" >
            <div className="mb-3 d-flex flex-column gap-4">
                <div className="d-flex gap-3">
                    <div className="d-flex flex-column gap-2" >
                        <Label color={dsVariables.colors.Gray[800]}>Tipo de ameaça*</Label>
                        <Select state={setTypeForm ? 'default' : 'disabled'} width={221} selected={ThreatTypeTextEnum.FAKE_PROFILE} required onChange={(e: any) => setTypeForm(e.target.value)} options={Object.values(ThreatTypeTextEnum).filter(el => el != ThreatTypeTextEnum.ALL)} />
                    </div>
                    <div className="d-flex flex-column gap-2">
                        <Label color={dsVariables.colors.Gray[800]}>URL da ameaça*</Label>
                        <Input state={setTypeForm ? 'default' : 'disabled'} error={isInvalidUrl} width={458} required placeholder="Insira a URL" value={profileForm.url} onChange={(e: any) => dispatch(changeProfileFormUrl(e.target.value))} />
                    </div>
                </div>
                <div className="d-flex gap-3">
                    <div className="d-flex flex-column gap-2" >
                        <Label color={dsVariables.colors.Gray[800]}>Plataforma*</Label>
                        <Select state={setTypeForm ? 'default' : 'disabled'} width={221} required onChange={(e: any) => dispatch(changeProfileFormPlatform(e.target.value))} options={Object.values(ThreatPlatformEnum).filter(el => el == ThreatPlatformEnum.FACEBOOK || el == ThreatPlatformEnum.INSTAGRAM || el == ThreatPlatformEnum.TIKTOK)} />
                    </div>
                    <div className="d-flex flex-column gap-2" >
                        <Label color={dsVariables.colors.Gray[800]}>Status*</Label>
                        <Select selected={profileForm.isActive ? ThreatStatusActiveEnum.ACTIVE : ThreatStatusActiveEnum.INACTIVE} width={221} required onChange={(e: any) => dispatch(changeProfileFormIsActive(e.target.value == ThreatStatusActiveEnum.ACTIVE))} options={Object.values(ThreatStatusActiveEnum)} />
                    </div>
                    <div className="d-flex flex-column gap-2" >
                        <Label color={dsVariables.colors.Gray[800]}>Criação do perfil</Label>
                        <div style={{ pointerEvents: setTypeForm ? 'auto' : 'none' }} className="d-flex align-items-stretch  position-relative">
                            <InputDate state={setTypeForm ? 'default' : 'disabled'} width={221} value={profileForm.searchDate} onChange={date => dispatch(changeProfileFormSearchDate(date[0]))} />
                            <div className="position-absolute pe-none end-0 top-0">
                                <ButtonPrimary state={setTypeForm ? 'default' : 'disabled'} onClick={() => null} iconLeft={CalendarMonth} iconLeftColor="white" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column gap-2" >
                    <Label color={dsVariables.colors.Gray[800]}>Perfil*</Label>
                    <Input state={setTypeForm ? 'default' : 'disabled'} width={221} placeholder="Ex: @wadewilson" required value={profileForm.profile} onChange={(e: any) => dispatch(changeProfileFormProfile(e.target.value))} />
                </div>
                <InputEvidence
                    files={profileForm.evidence}
                    onChangeLink={(e: any) => dispatch(changeProfileFormEvidenceLink(e.target.value))}
                    linkValue={profileForm.evidenceLink}
                    onDropFile={(e: any) => dispatch(changeProfileFormEvidenceFiles([...profileForm.evidence!, e[0]]))}
                    onRemoveFile={(file: File) => dispatch(changeProfileFormEvidenceFiles(profileForm.evidence!.filter(el => el != file)))}
                />
            </div>
        </form>
    )
}