import * as moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";

import HeatmapChart from "../../../Components/custom/HeatmapCharts";
import AnalyticalReportTable from "./AnalyticalReportTable";
import BouncingDotsLoader from "../../../Components/Common/BoucingDotsLoader";

const AnalyticalReportAggressorHeatmap = ({
  offenderGroupByDomainData,
  offenderGroupByDomainLoading,
  setHeatmapWords,
  selectedCampaign,
  adsLoading,
  adsError,
  ads,
  handleAds,
}) => {
  const [numElements, setNumElements] = useState(10);
  const [seriesChart, setSeriesChart] = useState(
    offenderGroupByDomainData?.slice(0, numElements)
  );
  const [modalChart, setModalChart] = useState(false);
  const [tableData, setTableData] = useState();
  const [selectedWordType, setSelectedWordType] = useState(0);

  const handleKeyword = (type) => {
    setSelectedWordType(type);
    setHeatmapWords(type === 0 ? "all" : type === 1 ? "main" : "secondary");
  };

  const isAccordionOpened = (isOpened) => {
    if (isOpened) {
      setNumElements(offenderGroupByDomainData?.length);
    } else {
      setNumElements(10);
    }
  };

  useEffect(() => {
    selectedCampaign?.keywords?.length > 1
      ? setSelectedWordType(0)
      : setSelectedWordType(1);
  }, [selectedCampaign]);

  useEffect(() => {
    setSeriesChart(offenderGroupByDomainData?.slice(0, numElements));
  }, [offenderGroupByDomainData, numElements]);

  const toggleModal = (data) => {
    if (data) {
      setTableData(data);
    }
    setModalChart(!modalChart);
  };

  const labels =
    seriesChart[0]?.data?.map((item) => {
      const date = moment(item.date);
      const format = item.date.length <= 7 ? "MMM YY" : "DD MMM";
      return date.format(format);
    }) || [];

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="card-title">Heatmap de Agressores</h4>

            <div className="card-header-actions">
              <div className="d-flex gap-1">
                {selectedCampaign?.keywords?.length > 1 && (
                  <button
                    type="button"
                    onClick={() => handleKeyword(0)}
                    className={
                      selectedWordType === 0
                        ? "btn btn-soft-primary btn-sm"
                        : "btn btn-soft-secondary btn-sm"
                    }
                  >
                    Todos
                  </button>
                )}
                <button
                  type="button"
                  onClick={() => handleKeyword(1)}
                  className={
                    selectedWordType === 1
                      ? "btn btn-soft-primary btn-sm"
                      : "btn btn-soft-secondary btn-sm"
                  }
                >
                  Palavras Principais
                </button>
                {selectedCampaign?.keywords?.length > 1 && (
                  <button
                    type="button"
                    onClick={() => handleKeyword(2)}
                    className={
                      selectedWordType === 2
                        ? "btn btn-soft-primary btn-sm"
                        : "btn btn-soft-secondary btn-sm"
                    }
                  >
                    Palavras Secundárias
                  </button>
                )}
              </div>
            </div>
          </div>
        </CardHeader>

        <CardBody className="mt-3">
          <div className="chart-container">
            <div className="chart-container__content w-100">
              {offenderGroupByDomainLoading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "30vh" }}
                >
                  <BouncingDotsLoader size={"24px"} />
                </div>
              ) : (
                <HeatmapChart
                  rangeTitle="Agressividade"
                  labelX={labels}
                  series={seriesChart}
                  color="#BC2727"
                  onChangeAccordion={isAccordionOpened}
                  onToggleModal={toggleModal}
                  selectedCampaign={selectedCampaign.value}
                  handleAds={handleAds}
                />
              )}
            </div>
          </div>

          <AnalyticalReportTable
            isOpen={modalChart}
            toggle={toggleModal}
            loading={adsLoading}
            error={adsError}
            ads={ads}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default AnalyticalReportAggressorHeatmap;
