import { ContentCopy } from "@mui/icons-material";
import Modal from "../../../../DesignSystem/components/Modal";
import { useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/store.interface";
import Body from "../../../../DesignSystem/components/Text/Body";
import { dsVariables } from "../../../../DesignSystem/variables";

interface IProps {
    isOpen: boolean
    onClose: any
    onConfirm: any
}

export default function ModalResolved({ isOpen, onClose, onConfirm }: IProps) {

    const { current, currentIds } = useSelector((store: IStore) => store.Threats)

    return (
        <Modal
            title="Marcar como Resolvida" subtitle={`Ameaça: ${current?.url}`} isOpen={isOpen} onClose={onClose}
            width={552}
            footerButtons={[
                { buttonStyle: 'secondary', onClick: onClose, label: 'Cancelar', type: 'destructive' },
                { buttonStyle: 'primary', onClick: onConfirm, label: 'Confirmar' },
            ]}
        >
            <Body color={dsVariables.colors.Gray[800]} style="medium">Tem certeza que deseja marcar como resolvida?</Body>.
            <br />
            {
                currentIds.length > 0
                    ? <Body color={dsVariables.colors.Gray[800]}>O total de <Body style="medium">{currentIds.length}</Body> ameaças foram selecionadas  e serão movidas para a aba <Body style="medium">Resolvidas</Body>. Esta ação não poderá ser desfeita.</Body>
                    : <Body color={dsVariables.colors.Gray[800]}>Foi selecionada a ameaça: {current?.url} e será movida para a aba <Body style="medium">Resolvidas</Body>. Esta ação não poderá ser desfeita.</Body>
            }
            <br />
            <br />
        </Modal>
    )
}