import { IProduct } from "../../interfaces/product.interface";

export interface ICompanyProductsInitialState {
    _id?: string;
    companyId: string;
    products: IProduct[]
}

export const CompanyProductsInitialState: ICompanyProductsInitialState = {
    companyId: '',
    products: [
        {
            productName: "fraud",
            enabled: true,
            trial: false,
            trialDateEnd: '',
            automaticApprovals: [
                {
                    product: "fake_site",
                    isAutomatic: true
                },
                {
                    product: "fake_ads",
                    isAutomatic: false
                },
                {
                    product: "fake_content",
                    isAutomatic: false
                },
                {
                    product: "fake_ads",
                    isAutomatic: true
                }
            ]
        }
    ]
}
