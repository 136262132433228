import { ThreatDaysEnum } from "../../enums/threat-days.enum"
import { ThreatPlatformEnum } from "../../enums/threat-platform.enum"
import { ThreatStatusActiveEnum } from "../../enums/threat-status-active.enum"
import { ThreatStateEnum } from "../../enums/threat-state.enum"
import { ThreatTypeEnum } from "../../enums/threat-type.enum"
import { IThreatForm } from "../../interfaces/threat-form.interface"
import { IThreatsFilter } from "../../interfaces/threats-filter.interface"
import { IThreatsTable } from "../../interfaces/threats-table.interface"
import { IThreats } from "../../interfaces/threats.interface"
import { IFraudsQuantityByState } from "../../interfaces/frauds-quantity-by-state.interface"
import { ITotalPagesByState } from "../../interfaces/total-pages-by-state.interface"

export interface IThreatInitialState {
    id: string
    filter: IThreatsFilter
    threatsList: IThreats[]
    threatsTableData: IThreatsTable[]
    forms: IThreatForm
    currentIds: string[]
    current?: IThreats
    isLoading: boolean
    fraudsTotalPagesByState: ITotalPagesByState
    fraudsQuantityByState: IFraudsQuantityByState
    fraudsFilteredQuantityByState: IFraudsQuantityByState
}

export const ThreatInitialState: IThreatInitialState = {
    id: '',
    filter: {
        ipf: '',
        platform: ThreatPlatformEnum.ALL,
        text: '',
        type: ThreatTypeEnum.ALL,
        days: ThreatDaysEnum.ALL_DAYS,
        status: true
    },
    threatsList: [],
    threatsTableData: [],
    forms: {
        adsForm: {
            type: ThreatTypeEnum.FAKE_AD,
            url: '',
            id: '',
            searchDate: new Date(),
            startDate: new Date(),
            platform: ThreatPlatformEnum.METAADS,
            advertiserName: '',
            advertiserId: '',
            instagramProfile: '',
            evidence: [],
            evidenceLink: '',
            isActive: true,
            adsQuantity:0
        },
        profileForm: {
            type: ThreatTypeEnum.FAKE_PROFILE,
            url: '',
            searchDate: new Date(),
            platform: ThreatPlatformEnum.FACEBOOK,
            profile: '',
            evidence: [],
            evidenceLink: '',
            isActive: true
        },
        contentForm: {
            type: ThreatTypeEnum.FAKE_PROFILE,
            url: '',
            platform: ThreatPlatformEnum.INSTAGRAM,
            profile: '',
            evidence: [],
            evidenceLink: '',
            isActive: true
        },
        siteForm: {
            type: ThreatTypeEnum.FAKE_SITE,
            url: '',
            searchDate: new Date(),
            evidence: [],
            evidenceLink: '',
            isActive: true
        },
    },
    currentIds: [],
    isLoading: false,
    fraudsQuantityByState: {
        awaiting_approval: 0,
        discarded: 0,
        new: 0,
        quarantine: 0,
        resolved: 0,
        under_discussion: 0
    },
    fraudsTotalPagesByState: {
        awaiting_approval: 0,
        discarded: 0,
        new: 0,
        quarantine: 0,
        resolved: 0,
        under_discussion: 0
    },
    fraudsFilteredQuantityByState: {
        awaiting_approval: 0,
        discarded: 0,
        new: 0,
        quarantine: 0,
        resolved: 0,
        under_discussion: 0
    },
}