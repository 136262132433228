import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { ActionTypesEnum } from "./types.enum";
import { getFraudsByCompanyId, registerFraudHelper, restoreFraudHelper, restoreMultipleFraudsHelper, updateFraudHelper, updateMultipleFraudsHelper } from "../../helpers/backend_helper";
import { addFraud, changeFraudsFilteredQuantityByState, changeFraudsQuantityByState, changeFraudsTotalPageByState, changeThreatsList, registerFraudFailure, setLoading, updateFraudFromSaga, updateMultipleFraudsFromSaga } from "./actions";
import { IThreats } from "../../interfaces/threats.interface";

function* getFraudsByCompanyByIdSaga({ payload }: any) {
  yield put(setLoading(true))
  try {
    const getFrauds: { frauds: IThreats[], fraudsByState: any, totalPagesByState: any } =
      yield call(getFraudsByCompanyId, {
        companyId: payload.companyId, itemsPerPage: payload.itemsPerPage, page: payload.page,
        type: payload.type, platform: payload.platform, status: payload.status,
        startDate: payload.startDate, endDate: payload.endDate, state: payload.state, text:payload.text
      })
    yield put(changeThreatsList(getFrauds.frauds))
    yield put(changeFraudsQuantityByState(getFrauds.fraudsByState))
    yield put(changeFraudsFilteredQuantityByState(getFrauds.fraudsByState))
    yield put(changeFraudsTotalPageByState(getFrauds.totalPagesByState))
  } catch {
    yield put(changeThreatsList([]));
  } finally {
    yield put(setLoading(false))
  }
}

function* registerFraudSaga({ payload }: any) {
  try {
    const formData = new FormData()

    for (const [key, value] of Object.entries(payload)) {
      if (key === 'evidence') {
        for (let file of value as File[]) {
          if (file instanceof File) {
            formData.append('evidence', file);
          }
        }
      } else formData.append(key, value as any);
    }

    const newFraud: IThreats = yield call(registerFraudHelper, formData);
    yield put(addFraud(newFraud));
    yield put(setLoading(false))
  } catch (error: any) {
    yield put(registerFraudFailure(error.data.message));
    yield put(setLoading(false))
  }
}

function* updateFraudSaga({ payload }: any) {
  try {
    const formData = new FormData()

    for (const [key, value] of Object.entries({
      currentState: payload.currentState, isActive: payload.isActive, _id: payload._id,
      evidenceLink: payload.evidenceLink, evidence: payload.evidence || []
    })) {
      if (key === 'evidence') {
        for (let file of value as File[]) {
          if (file instanceof File) {
            formData.append('evidence', file);
          }
        }
      } else formData.append(key, value as any);
    }

    const updatedFraud: IThreats = yield call(updateFraudHelper, formData);
    yield put(updateFraudFromSaga(updatedFraud));
    yield put(setLoading(false))
  } catch (error) {
    // yield put(registerFraudFailure(error.message)); // Dispatch failure action
  }
}
function* restoreFraudSaga({ payload }: any) {
  try {
    const updatedFraud: IThreats = yield call(restoreFraudHelper, {
      currentState: payload.currentState, isActive: payload.isActive.toString(), _id: payload._id,
    });
    yield put(updateFraudFromSaga(updatedFraud));
    yield put(setLoading(false))
  } catch (error) {
    // yield put(registerFraudFailure(error.message)); // Dispatch failure action
  }
}

function* updateMultipleFraudsSaga({ payload }: any) {
  try {
    const updatedFrauds: IThreats[] = yield call(updateMultipleFraudsHelper, payload);
    yield put(updateMultipleFraudsFromSaga(updatedFrauds));
    yield put(setLoading(false))
  } catch (error) {
    // yield put(registerFraudFailure(error.message)); // Dispatch failure action
  }
}
function* restoreMultipleFraudsSaga({ payload }: any) {
  try {
    const updatedFrauds: IThreats[] = yield call(restoreMultipleFraudsHelper, payload);
    yield put(updateMultipleFraudsFromSaga(updatedFrauds));
    yield put(setLoading(false))
  } catch (error) {
    // yield put(registerFraudFailure(error.message)); // Dispatch failure action
  }
}

export function* watchAll() {
  yield takeLatest(ActionTypesEnum.GET_FRAUDS_BY_COMPANY, getFraudsByCompanyByIdSaga);
  yield takeLatest(ActionTypesEnum.REGISTER_FRAUD, registerFraudSaga);
  yield takeLatest(ActionTypesEnum.UPDATE_FRAUD, updateFraudSaga);
  yield takeLatest(ActionTypesEnum.UPDATE_MULTIPLE_FRAUDS, updateMultipleFraudsSaga);
  yield takeLatest(ActionTypesEnum.RESTORE_MULTIPLE_FRAUDS, restoreMultipleFraudsSaga);
  yield takeLatest(ActionTypesEnum.RESTORE_FRAUD, restoreFraudSaga);
}

function* threatSaga() {
  yield all([
    call(watchAll)
  ]);
}
export default threatSaga 