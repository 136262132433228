import Flatpickr from "react-flatpickr";
import { MAX_DATE } from "../SectionTitle/SectionTitle";
import { useRef } from "react";
import { dsVariables } from "../../variables";

interface IProps {
    onChange: (date: Date[]) => void
    value: Date
    width?: number
    side?: 'left' | 'right'
    state?: "default" | "disabled"
}

export default function InputDate({ onChange, value, width = 103, side, state = 'default' }: IProps) {

    const flatpickrStart = useRef(null);

    return (
        <Flatpickr
            disabled={state == 'disabled'}
            style={{
                width,
                height: dsVariables.spacing[36], gap: dsVariables.spacing[8],
                borderRadius: dsVariables.cornerRadius[4],
                borderWidth: 1, borderStyle: 'solid',
                borderColor: state == 'disabled' ? dsVariables.colors.Gray[100] : dsVariables.colors.Gray[400], ...dsVariables.texts.label.regular,
                color: state == 'disabled' ? dsVariables.colors.Gray[300] : dsVariables.colors.Gray[800],
                paddingBlock: dsVariables.spacing[8], paddingInline: dsVariables.spacing[12]
            }}
            options={{
                dateFormat: "d/m/Y",
                // @ts-ignore
                value: [value],
                defaultDate: value,
                maxDate: MAX_DATE,
            }}
            onChange={onChange}
            ref={flatpickrStart}
        />
    )
}