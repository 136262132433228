import Dropzone from "react-dropzone"
import styled from "styled-components"
import { dsVariables } from "../../variables"
import Icon from "../Icon"
import { CloudUpload, DeleteOutline, Web } from "@mui/icons-material"
import Body from "../Text/Body"
import Caption from "../Text/Caption"
import Input from "./Input"
import Label from "../Text/Label"

interface IProps {
    onDropFile: any
    onChangeLink: any
    linkValue?: string
    files?: File[]
    onRemoveFile: any
}

export default function InputEvidence({ onChangeLink, onDropFile, files, linkValue, onRemoveFile }: IProps) {

    const accept = {
        'image/*': ['.pdf'],
    }

    return (
        <div className="d-flex flex-column gap-2 w-100 ">
            <Dropzone onDropRejected={() => alert('formato não aceito')} accept={accept} onDropAccepted={onDropFile}>
                {({ getRootProps, getInputProps }) => (
                    <Div className="d-flex flex-column gap-2 align-items-center" {...getRootProps()}>
                        <Icon IconComponent={CloudUpload} width={48} height={48} color={dsVariables.colors.Gray[800]} />
                        <Body color={dsVariables.colors.Gray[800]}>*Arraste e Solte suas evidências ou <Body><span style={{ color: dsVariables.colors.Astronaut[900] }} className="text-decoration-underline">Busque</span></Body></Body>
                        <Caption color={dsVariables.colors.Gray[800]}>Formatos aceitos: jpg, jpeg, png, pdf</Caption>
                        <Body color={dsVariables.colors.Gray[800]} style="medium">Ou</Body>
                        <div >
                            <Input value={linkValue} iconLeft={Web} width={450} onChange={onChangeLink} placeholder="Insira o link da evidência" />
                        </div>
                        <input {...getInputProps()} />
                    </Div>
                )}
            </Dropzone>

            <div className="d-flex flex-column gap-2">
                {files?.map((file, index) => (
                    <FilesCard key={index} className="d-flex justify-content-between align-items-center px-3 py-2">
                        <div className="d-flex gap-2 align-items-center">
                            {/* @ts-ignore */}
                            <img className="object-fit-contain" width={42} height={42} src={!file?.name ? file : URL.createObjectURL(file.name.includes('svg') ? file : new Blob([file]))} alt={file.name} />
                            <div className="d-flex flex-column gap-1">
                                <Label>{file.name || `Evidência ${++index}`}</Label>
                                {file.size && <Label>{file.size / 1024} Kb</Label>}
                            </div>
                        </div>
                        <button onClick={() => onRemoveFile(file)} className="border-0 bg-transparent">
                            <Icon IconComponent={DeleteOutline} color={dsVariables.colors.Red[600]} />
                        </button>
                    </FilesCard>
                ))}
            </div>
        </div >
    )
}

const Div = styled.div`
    background-color: ${dsVariables.colors.Gray[50]};
    padding: ${dsVariables.spacing[24]} ${dsVariables.spacing[32]};
    border: 1px dashed ${dsVariables.colors.Gray[200]};
    border-radius: ${dsVariables.cornerRadius[8]};
`

const FilesCard = styled.div`
    background-color: ${dsVariables.colors.Gray[50]};
    border-radius: ${dsVariables.cornerRadius[8]};
    border: 1px solid ${dsVariables.colors.Gray[200]};
`