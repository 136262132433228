import Flatpickr from "react-flatpickr";
import { useEffect, useRef, useState } from "react";
import moment from "moment-timezone";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss"
import { changeFilterDateRangeEnd, changeFilterDateRangeStart, changeFilterDays } from "../../../store/threats/actions";
import { ThreatDaysEnum } from "../../../enums/threat-days.enum";
import { dsVariables } from "../../variables";
import styled from "styled-components";

const startDate = moment().subtract(14, "days").startOf("day").toDate();
export const MAX_DATE = moment().add(0, "days").endOf("day").tz("America/Sao_Paulo").toDate();

export default function SectionTitle({ type = 'primary', title }) {

    const dispatch = useDispatch();

    const flatpickrStartDate = useRef(null);
    const flatpickrEndDate = useRef(null);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const navigate = useNavigate();

    const { filter } = useSelector(state => {
        return {
            filter: state.Threats.filter,
        }
    });

    function areDatesEqual(date1, date2) {
        return (
            date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate()
        );
    }

    useEffect(() => {
        if (!searchParams.get('startDate') && !searchParams.get('endDate')) {
            searchParams.set('startDate', startDate);
            searchParams.set('endDate', MAX_DATE);
        } else if (!searchParams.get('startDate')) {
            searchParams.set('startDate', startDate);
        } else if (!searchParams.get('endDate')) {
            searchParams.set('endDate', MAX_DATE);
        }
        if (searchParams.get('startDate') && searchParams.get('endDate')) {
            dispatch(changeFilterDateRangeStart(new Date(searchParams.get('startDate'))))
            dispatch(changeFilterDateRangeEnd(new Date(searchParams.get('endDate'))))
        }

        if (!searchParams.get('days')) {
            searchParams.set('days', ThreatDaysEnum.ALL_DAYS);
        } else {
            const today = new Date();

            switch (searchParams.get('days')) {
                case ThreatDaysEnum.DAYS_7:
                    dispatch(changeFilterDays(ThreatDaysEnum.DAYS_7));
                    var startDate7 = new Date(new Date(today.setDate(today.getDate() - 7)).setHours(0,0,0));
                    var endDate7 = new Date();

                    if (!searchParams.get('startDate') || !areDatesEqual(new Date(searchParams.get('startDate')), startDate7)) {
                        searchParams.set('startDate', startDate7.toISOString());
                        dispatch(changeFilterDateRangeStart(null))
                    }
                    if (!searchParams.get('endDate') || !areDatesEqual(new Date(searchParams.get('endDate')), endDate7)) {
                        searchParams.set('endDate', endDate7.toISOString());
                        dispatch(changeFilterDateRangeEnd(null))
                    }
                    break;

                case ThreatDaysEnum.DAYS_14:
                    dispatch(changeFilterDays(ThreatDaysEnum.DAYS_14));
                    var startDate14 = new Date(new Date(today.setDate(today.getDate() - 14)).setHours(0,0,0));
                    var endDate14 = new Date();

                    if (!searchParams.get('startDate') || !areDatesEqual(new Date(searchParams.get('startDate')), startDate14)) {
                        searchParams.set('startDate', startDate14.toISOString());
                        dispatch(changeFilterDateRangeStart(null))
                    }
                    if (!searchParams.get('endDate') || !areDatesEqual(new Date(searchParams.get('endDate')), endDate14)) {
                        searchParams.set('endDate', endDate14.toISOString());
                        dispatch(changeFilterDateRangeEnd(null))
                    }
                    break;

                case ThreatDaysEnum.DAYS_28:
                    dispatch(changeFilterDays(ThreatDaysEnum.DAYS_28));
                    var startDate28 = new Date(new Date(today.setDate(today.getDate() - 28)).setHours(0,0,0));
                    var endDate28 = new Date();

                    if (!searchParams.get('startDate') || !areDatesEqual(new Date(searchParams.get('startDate')), startDate28)) {
                        searchParams.set('startDate', startDate28.toISOString());
                        dispatch(changeFilterDateRangeStart(null))
                    }
                    if (!searchParams.get('endDate') || !areDatesEqual(new Date(searchParams.get('endDate')), endDate28)) {
                        searchParams.set('endDate', endDate28.toISOString());
                        dispatch(changeFilterDateRangeEnd(null))
                    }
                    break;

                case ThreatDaysEnum.ALL_DAYS:
                    dispatch(changeFilterDays(ThreatDaysEnum.ALL_DAYS));
                    break;

                default:
                    break;
            }
        }

        const newSearch = searchParams.toString();

        if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
    }, [location]);

    return (
        <div className={`justify-content-between d-flex align-items-center div div-${type} flex-wrap`}>
            <h1 className="title">{title}</h1>

            <DateContainer className="d-flex align-items-center flex-wrap">
                <DatePicker className="d-flex" >
                    {filter.dateRange?.startDate && <Flatpickr
                        className="date-picker date-picker-left"
                        options={{
                            dateFormat: "d/m/Y",
                            value: [filter.dateRange?.startDate],
                            defaultDate: filter.dateRange?.startDate,
                            maxDate: MAX_DATE,
                            disableMobile: "true",
                        }}
                        onChange={(date) => {
                            searchParams.set('startDate', date[0]);
                            searchParams.set('days', ThreatDaysEnum.ALL_DAYS);
                            searchParams.set('page', '1');
                            const newSearch = searchParams.toString();
                            if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
                        }}
                        ref={flatpickrStartDate}
                    />}

                    {filter.dateRange?.endDate && <Flatpickr
                        className="date-picker date-picker-right"
                        options={{
                            dateFormat: "d/m/Y",
                            value: [filter.dateRange?.endDate],
                            defaultDate: filter.dateRange?.endDate,
                            maxDate: MAX_DATE,
                            disableMobile: "true",
                        }}
                        onChange={(date) => {
                            searchParams.set('endDate', date[0]);
                            searchParams.set('days', ThreatDaysEnum.ALL_DAYS);
                            searchParams.set('page', '1');
                            const newSearch = searchParams.toString();
                            if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
                        }}
                        ref={flatpickrEndDate}
                    />}
                    <button className="calendar-btn"><i style={{ width: 16, height: 16 }} className="ri-calendar-2-line text-white"></i></button>
                </DatePicker>

                <DaysContainer className="d-flex">
                    <DayButton
                        type="button"
                        onClick={() => {
                            searchParams.set('days', ThreatDaysEnum.DAYS_7);
                            const newSearch = searchParams.toString();
                            if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
                        }}
                        selected={filter.days && filter.days === ThreatDaysEnum.DAYS_7}
                    >
                        07{filter.days && filter.days === ThreatDaysEnum.DAYS_7 && <>&nbsp;dias</>}
                    </DayButton>
                    <DayButton
                        type="button"
                        onClick={() => {
                            searchParams.set('days', ThreatDaysEnum.DAYS_14);
                            const newSearch = searchParams.toString();
                            if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
                        }}
                        selected={filter.days && filter.days === ThreatDaysEnum.DAYS_14}
                    >
                        14{filter.days && filter.days === ThreatDaysEnum.DAYS_14 && <>&nbsp;dias</>}
                    </DayButton>
                    <DayButton
                        type="button"
                        onClick={() => {
                            searchParams.set('days', ThreatDaysEnum.DAYS_28);
                            const newSearch = searchParams.toString();
                            if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
                        }}
                        selected={filter.days && filter.days === ThreatDaysEnum.DAYS_28}
                    >
                        28{filter.days && filter.days === ThreatDaysEnum.DAYS_28 && <>&nbsp;dias</>}
                    </DayButton>
                    <DayButton
                        type="button"
                        onClick={() => {
                            searchParams.set('days', ThreatDaysEnum.ALL_DAYS);
                            const newSearch = searchParams.toString();
                            if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
                        }}
                        selected={filter.days && filter.days === ThreatDaysEnum.ALL_DAYS}
                    >
                        Tudo
                    </DayButton>
                </DaysContainer>
            </DateContainer>
        </div>
    )
}

const DayButton = styled.button`
    background-color: ${props => props.selected ? dsVariables.colors.Chambray[100] : dsVariables.colors.AzureRadiance[100]} !important;
    color: ${props => props.selected ? dsVariables.colors.Chambray[800] : dsVariables.colors.Chambray[500]} !important;
    border-radius: ${dsVariables.cornerRadius[4]};
    border: ${props => props.selected ? `
        1px solid ${dsVariables.colors.Chambray[800]}`
        : `none`
    };
    padding:${dsVariables.spacing[4]} ${dsVariables.spacing[8]}!important;
    height: ${dsVariables.spacing[32]};

    &:hover {
        background-color: ${dsVariables.colors.AzureRadiance[200]} !important;
    }
`

const DateContainer = styled.div`
    gap: ${dsVariables.spacing[24]};
`

const DaysContainer = styled.div`
gap: ${dsVariables.spacing[8]};
`

const DatePicker = styled.div`
 ${dsVariables.shadows.Down.Blur2}
`
