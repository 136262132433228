import { ActionTypesEnum } from "./types.enum";

export const getAllCompaniesToAdmin = () => ({
  type: ActionTypesEnum.FETCH_COMPANIES,
});

export const changeAdminCompaniesList = (companies: any[]) => ({
  type: ActionTypesEnum.CHANGE_COMPANIES_LIST,
  payload: companies
});

export const getCompaniesGroup = (companyId: string) => ({
  type: ActionTypesEnum.FETCH_COMPANIES_GROUP,
  payload: companyId
});

export const changeAdminCompaniesGroupList = (companies: any[]) => ({
  type: ActionTypesEnum.CHANGE_COMPANIES_GROUP_LIST,
  payload: companies
});

export const inviteUser = (data: any) => ({
  type: ActionTypesEnum.INVITE_USER,
  payload: data
});

export const changeUserPermissions = (data: any) => ({
  type: ActionTypesEnum.CHANGE_USER_PERMISSIONS,
  payload: data
});

export const getUserToChangePermissions = (email:string) => ({
  type: ActionTypesEnum.FETCH_USER_TO_CHANGE,
  payload: email
});

export const changeUser = (data:any) => ({
  type: ActionTypesEnum.CHANGE_USER,
  payload: data
});

export const changeUsers = (data:any) => ({
  type: ActionTypesEnum.CHANGE_USERS,
  payload: data
});

export const fetchUsers = () => ({
  type: ActionTypesEnum.FETCH_USERS,
});