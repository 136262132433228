import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from 'jwt-decode';

import { localStorageKeys } from "../config/localStorageKeys";
import {
  loginError,
  resetProfileFlag,
  setUserProfile,
} from "../store/actions";
import { useTranslation } from "react-i18next";
import { Fade } from "reactstrap";
import ValidateTwoFactor from "../pages/Authentication/ValidateTwoFactor";
import { getCompanyProductsByCompanyId } from "../store/companyProducts/action";
import useIsEnabledOrTrial from "../DesignSystem/hooks/IsEnabledOrTrial.hook";

const AuthProtected = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectProfileState = (state) => state.Profile;
  const { user, userLoggedIn, accessForbidden } =
    useSelector(selectProfileState);

  const { companyProducts, selectedCompany } = useSelector(store => {
    return {
      companyProducts: store.CompanyProducts.products,
      selectedCompany: store.Profile.selectedCompany,
    }
  })

  const { atLeastOne: isBBEnabledOrTrial } = useIsEnabledOrTrial("brand bidding")
  const { atLeastOne: isFraudEnabledOrTrial } = useIsEnabledOrTrial("fraud")

  const checkUserToken = useCallback(() => {
    const accessToken = localStorage.getItem(localStorageKeys.AUTH_USER);

    if (accessToken === null || accessToken === undefined) {
      return null;
    }

    if (!accessToken) {
      return false;
    }

    const decodedToken = jwtDecode(accessToken);

    if (decodedToken.exp) {
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) return false;

      return true;
    }

    return true;
  }, []);

  const saveUrlPath = () => {
    const path = window.location.pathname + window.location.search
    localStorage.setItem("redirectToSuccessLogin", path)
  }

  useEffect(() => {
    const isTokenValid = checkUserToken();

    if (!userLoggedIn && isTokenValid) {
      dispatch(setUserProfile())
      return
    }

    if (!isTokenValid || !userLoggedIn) {
      (isTokenValid || userLoggedIn) &&
        dispatch(loginError(t("page.authentication.login.session-expired")));
      localStorage.removeItem(localStorageKeys.AUTH_USER);
      saveUrlPath()
      navigate("/login");
      dispatch(resetProfileFlag());
      return
    }
    if (user.companies?.length > 0 && (!companyProducts || companyProducts.length == 1)) {
      dispatch(getCompanyProductsByCompanyId(selectedCompany.value))
      return
    }

    if (user.isCustomer && companyProducts.length == 2) {
      const path = window.location.pathname
      const isFraudRoute = path.includes('manage-threats')
      const isFraudEnabled = isFraudEnabledOrTrial
      if (isFraudRoute && !isFraudEnabled) navigate("/panel?showModalFraudCta");

      const isBBRoute = !path.includes('manage-threats')

      if (isBBRoute && !isBBEnabledOrTrial && isFraudEnabled) {
        setTimeout(() => {
          navigate("/manage-threats?showModalBBCta");
        }, 50);
      }
    }

    const redirectToSuccessLogin = localStorage.getItem("redirectToSuccessLogin")
    if (redirectToSuccessLogin) {
      navigate(redirectToSuccessLogin)
      localStorage.removeItem("redirectToSuccessLogin")
    }
  }, [checkUserToken, dispatch, navigate, userLoggedIn, t, accessForbidden, selectedCompany, companyProducts]);

  return (
    <>
      {userLoggedIn && (
        <>
          {localStorage.getItem("token") && (
            <ValidateTwoFactor />
          )}
          <Fade> {props.children} </Fade>
        </>
      )}
    </>
  );
};

export { AuthProtected };
