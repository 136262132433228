import moment from 'moment-timezone';

export const normalizeEndDate = (endDate) => {
  let dateObj;

  if (typeof endDate === "string") {
    dateObj = moment.tz(endDate, "America/Sao_Paulo");
  } else if (endDate instanceof Date) {
    dateObj = moment(endDate).tz("America/Sao_Paulo");
  } else {
    throw new Error("Formato de data inválido");
  }

  dateObj.set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 0,
  });

  return dateObj.format();
};

export const normalizeStartDate = (startDate) => {
  let dateObj;

  if (typeof startDate === "string") {
    dateObj = moment.tz(startDate, "America/Sao_Paulo");
  } else if (startDate instanceof Date) {
    dateObj = moment(startDate).tz("America/Sao_Paulo");
  } else {
    throw new Error("Formato de data inválido");
  }

  return dateObj.format();
};

