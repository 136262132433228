import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Form, Label, Input, Spinner, Alert } from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import iconCheck from "../../assets/images/icons/check.png";
import { useTranslation } from "react-i18next";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  registerUser,
  resetRegisterFlag,
} from "../../store/auth/register/actions";
import {
  resetPassword,
  resetPasswordFlag,
} from "../../store/auth/resetpwd/actions";
import withRouter from "../../Components/Common/withRouter";

import { useTermsAcceptance } from "../../Components/Common/ModalTermsAndPrivacy";
import TermsAndPrivacy from "../../Components/Common/ModalTermsAndPrivacy";
import { dsVariables } from "../../DesignSystem/variables";

const PasswordCreate = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const actualUrl = window.location.href;
  const isLinkToPasswordReset = actualUrl.includes("password-reset");

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");

  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

  const [isMinLength, setIsMinLength] = useState();
  const [isLowercase, setIsLowercase] = useState();
  const [isUppercase, setIsUppercase] = useState();
  const [isNumber, setIsNumber] = useState();
  const [isSpecial, setIsSpecial] = useState();
  const [isPasswordEqual, setIsPasswordEqual] = useState();

  const { agree, setAgree } = useTermsAcceptance();

  const selectRegisterState = (state) => state.Register;
  const {
    registerLoading,
    registerError,
    isPasswordCreated,
    registerErrorMessage,
  } = useSelector(selectRegisterState);
  const selectResetPasswordState = (state) => state.ResetPassword;
  const { resetLoading, resetError, resetIsPasswordCreated } = useSelector(
    selectResetPasswordState
  );
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      confirm_password: "",
    },

    validationSchema: Yup.object(
      {
        password: Yup.lazy(() =>
          Yup.string()
            .when("password", (password) => {
              if (password[0]?.length >= 8) {
                setIsMinLength(true);
              } else {
                setIsMinLength(false);
              }
            })
            .when("password", (password) => {
              if (/[A-Z]+/.test(password[0]) && password[0]?.length > 0) {
                setIsUppercase(true);
              } else {
                setIsUppercase(false);
              }
            })
            .when("password", (password) => {
              if (/[a-z]+/.test(password[0]) && password[0]?.length > 0) {
                setIsLowercase(true);
              } else {
                setIsLowercase(false);
              }
            })
            .when("password", (password) => {
              if (/\d/.test(password[0]) && password[0]?.length > 0) {
                setIsNumber(true);
              } else {
                setIsNumber(false);
              }
            })
            .when("password", (password) => {
              if (/[@$!%*#?&]+/.test(password[0]) && password[0]?.length > 0) {
                setIsSpecial(true);
              } else {
                setIsSpecial(false);
              }
            })
        ),

        confirm_password: Yup.lazy(() =>
          Yup.string().when(["confirm_password", "password"], (password) => {
            if (password[0] === password[1] && password[0]?.length > 0) {
              setIsPasswordEqual(true);
            } else {
              setIsPasswordEqual(false);
            }
          })
        ),
      },
      ["password", "confirm_password"]
    ),

    onSubmit: (values) => {
      let user = {
        token: token,
        password: values.password,
        ...(isLinkToPasswordReset ? {} : { acceptedTermsAndPrivacy: agree }),
      };
      if (isLinkToPasswordReset) {
        dispatch(resetPassword(user, props.router.navigate));
      } else {
        dispatch(registerUser(user, props.router.navigate));
      }
    },
  });

  useEffect(() => {
    if (registerError) {
      setTimeout(() => {
        dispatch(resetRegisterFlag());
      }, 4000);
    }

    if (resetError) {
      setTimeout(() => {
        dispatch(resetPasswordFlag());
      }, 4000);
    }
  }, [dispatch, registerError, resetError]);
  useEffect(() => {
    console.log(agree);
  }, [agree]);
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="w-lg-50" style={{ backgroundColor: dsVariables.colors.Gray[50], borderTopRightRadius: dsVariables.cornerRadius[4], borderBottomRightRadius: dsVariables.cornerRadius[4] }}>
          {!isPasswordCreated && !resetIsPasswordCreated ? (
            <div className="p-3">
              <h5 className="text-primary">
                {t("page.authentication.password-create.title")}
              </h5>
              {isLinkToPasswordReset ? (
                <p className="text-muted">
                  {t("page.authentication.password-create.subtitle")}
                </p>
              ) : (
                <p className="text-muted">
                  {t("page.authentication.first-password-create.subtitle")}
                </p>
              )}
              <div className="p-2">
                {(registerError || resetError) && (
                  <Alert color="danger">
                    {registerErrorMessage === "Invite was expired"
                      ? t(
                        "page.authentication.first-password-create.error-expired"
                      )
                      : t("page.authentication.first-password-create.error")}
                  </Alert>
                )}

                <Form onSubmit={validation.handleSubmit} action="/login">
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="password-input">
                      {t("page.authentication.password-create.label.password")}
                    </Label>
                    <div className="position-relative auth-pass-inputgroup mb-2">
                      <Input
                        type={passwordShow ? "text" : "password"}
                        className="form-control pe-5 password-input"
                        placeholder={t(
                          "page.authentication.password-create.placeholder.password"
                        )}
                        id="password-input"
                        name="password"
                        onBlur={validation.handleBlur}
                        onChange={validation.handleChange}
                      />
                      <Button
                        color="link"
                        onClick={() => setPasswordShow(!passwordShow)}
                        className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                        id="password-addon"
                      >
                        <i className="ri-eye-fill align-middle"></i>
                      </Button>
                    </div>

                    <div
                      id="passwordInput"
                      className={`${isMinLength
                        ? "form-text d-flex text-success"
                        : "form-text d-flex"
                        }`}
                    >
                      {isMinLength ? (
                        <i className="ri-checkbox-circle-line align-middle me-1"></i>
                      ) : (
                        <i className="ri-close-circle-line align-middle me-1"></i>
                      )}
                      {t(
                        "page.authentication.password-create.rule.min-characters"
                      )}
                    </div>

                    <div
                      id="passwordInput"
                      className={`${isUppercase
                        ? "form-text d-flex text-success"
                        : "form-text d-flex"
                        }`}
                    >
                      {isUppercase ? (
                        <i className="ri-checkbox-circle-line align-middle me-1"></i>
                      ) : (
                        <i className="ri-close-circle-line align-middle me-1"></i>
                      )}
                      {t(
                        "page.authentication.password-create.rule.uppercase-letter"
                      )}
                    </div>

                    <div
                      id="passwordInput"
                      className={`${isLowercase
                        ? "form-text d-flex text-success"
                        : "form-text d-flex"
                        }`}
                    >
                      {isLowercase ? (
                        <i className="ri-checkbox-circle-line align-middle me-1"></i>
                      ) : (
                        <i className="ri-close-circle-line align-middle me-1"></i>
                      )}
                      {t(
                        "page.authentication.password-create.rule.lowercase-letter"
                      )}
                    </div>

                    <div
                      id="passwordInput"
                      className={`${isNumber
                        ? "form-text d-flex text-success"
                        : "form-text d-flex"
                        }`}
                    >
                      {isNumber ? (
                        <i className="ri-checkbox-circle-line align-middle me-1"></i>
                      ) : (
                        <i className="ri-close-circle-line align-middle me-1"></i>
                      )}
                      {t("page.authentication.password-create.rule.number")}
                    </div>

                    <div
                      id="passwordInput"
                      className={`${isSpecial
                        ? "form-text d-flex text-success"
                        : "form-text d-flex"
                        }`}
                    >
                      {isSpecial ? (
                        <i className="ri-checkbox-circle-line align-middle me-1"></i>
                      ) : (
                        <i className="ri-close-circle-line align-middle me-1"></i>
                      )}
                      {t(
                        "page.authentication.password-create.rule.special-character"
                      )}
                    </div>

                    <div
                      id="passwordInput"
                      className={`${isPasswordEqual
                        ? "form-text d-flex text-success"
                        : "form-text d-flex"
                        }`}
                    >
                      {isPasswordEqual ? (
                        <i className="ri-checkbox-circle-line align-middle me-1"></i>
                      ) : (
                        <i className="ri-close-circle-line align-middle me-1"></i>
                      )}
                      {t("page.authentication.password-create.equal.password")}
                    </div>
                    {!isLinkToPasswordReset && (
                      <div
                        className={`${agree
                          ? "form-text d-flex text-success"
                          : "form-text d-flex"
                          }`}
                      >
                        {agree ? (
                          <i className="ri-checkbox-circle-line align-middle me-1"></i>
                        ) : (
                          <i className="ri-close-circle-line align-middle me-1"></i>
                        )}
                        {t(
                          "page.authentication.terms-privacy.rule.accepted-terms"
                        )}
                      </div>
                    )}
                  </div>

                  <div className="mb-3">
                    <Label
                      className="form-label"
                      htmlFor="confirm-password-input"
                    >
                      {t(
                        "page.authentication.password-create.label.password-confirm"
                      )}
                    </Label>
                    <div className="position-relative auth-pass-inputgroup mb-3">
                      <Input
                        type={confirmPasswordShow ? "text" : "password"}
                        className="form-control pe-5 password-input"
                        placeholder={t(
                          "page.authentication.password-create.placeholder.password-confirm"
                        )}
                        id="confirm-password-input"
                        name="confirm_password"
                        onBlur={validation.handleBlur}
                        onChange={validation.handleChange}
                      />
                      <Button
                        color="link"
                        onClick={() =>
                          setConfirmPasswordShow(!confirmPasswordShow)
                        }
                        className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                      >
                        <i className="ri-eye-fill align-middle"></i>
                      </Button>
                    </div>
                  </div>

                  <div className="form-check">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="auth-remember-check"
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="auth-remember-check"
                    >
                      {t("page.authentication.password-create.keep-conected")}
                    </Label>
                    {isLinkToPasswordReset ? null : (
                      <TermsAndPrivacy agree={agree} setAgree={setAgree} />
                    )}
                  </div>

                  <div className="mt-4">
                    <Button
                      disabled={
                        (!isLinkToPasswordReset && !agree) ||
                        registerLoading ||
                        resetLoading ||
                        !isMinLength ||
                        !isUppercase ||
                        !isLowercase ||
                        !isNumber ||
                        !isSpecial ||
                        !isPasswordEqual
                      }
                      color="secondary"
                      className="w-100 border-0 d-flex justify-content-center align-items-center"
                      type="submit"
                      style={{ backgroundColor: dsVariables.colors.Astronaut[700] }}
                    >
                      {(registerLoading || resetLoading) && (
                        <Spinner size="sm" className="me-2" />
                      )}
                      {t(
                        "page.authentication.password-create.btn.create-password"
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center h-100">
              <div className="p-lg-5 p-4 text-center">
                <img src={iconCheck} alt="" height="80" />

                <div className="mt-3 pt-2">
                  <h5>
                    {t(
                      "page.authentication.password-create.message-success-title"
                    )}
                  </h5>
                  <p className="text-muted">
                    {t(
                      "page.authentication.password-create.message-success-subtitle"
                    )}
                  </p>
                  <div className="mt-4">
                    <Link to="/panel" className="btn btn-secondary border-0 w-100"
                      style={{ backgroundColor: dsVariables.colors.Astronaut[700] }}
                    >
                      {t("page.authentication.password-create.btn.go-panel")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(PasswordCreate);
