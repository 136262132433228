import { ThreatDaysEnum } from "../../enums/threat-days.enum";
import { ThreatInitialState } from "./state";
import { ActionTypesEnum } from "./types.enum";

const threatReducer = (state = ThreatInitialState, action: { type: ActionTypesEnum, payload: any }) => {
  switch (action.type) {
    case ActionTypesEnum.SUBMIT_FORM:
      return {
        ...state,
        forms: {
          formLoading: true,
          formError: false,
        }
      };

    case ActionTypesEnum.SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        forms: {
          formLoading: false,
          formError: false,
          formErrorMessage: null,
        }
      };

    case ActionTypesEnum.SUBMIT_FORM_ERROR:
      return {
        ...state,
        forms: {
          formLoading: false,
          formError: true,
          formErrorMessage: action.payload,
        }
      };

    case ActionTypesEnum.CHANGE_FILTER_TEXT:
      return {
        ...state,
        filter: {
          ...state.filter,
          text: action.payload,
        },
      };

    case ActionTypesEnum.CHANGE_STATUS_PLATFORM:
      return {
        ...state,
        filter: {
          ...state.filter,
          status: action.payload,
        },
      };

    case ActionTypesEnum.CHANGE_FILTER_TYPE:
      return {
        ...state,
        filter: {
          ...state.filter,
          type: action.payload,
        },
      };

    case ActionTypesEnum.CHANGE_FILTER_PLATFORM:
      return {
        ...state,
        filter: {
          ...state.filter,
          platform: action.payload,
        },
      };

    case ActionTypesEnum.CHANGE_FILTER_IPF:
      return {
        ...state,
        filter: {
          ...state.filter,
          ipf: action.payload,
        },
      };

    case ActionTypesEnum.CHANGE_FILTER_DATE_RANGE_START:
      return {
        ...state,
        filter: {
          ...state.filter,
          dateRange: {
            ...state.filter.dateRange,
            startDate: action.payload,
          },
          days: ThreatDaysEnum.ALL_DAYS
        },
      };

    case ActionTypesEnum.CHANGE_FILTER_DATE_RANGE_END:
      return {
        ...state,
        filter: {
          ...state.filter,
          dateRange: {
            ...state.filter.dateRange,
            endDate: action.payload,
          },
          days: ThreatDaysEnum.ALL_DAYS
        },
      };

    case ActionTypesEnum.CHANGE_FILTER_DAYS:
      return {
        ...state,
        filter: {
          ...state.filter,
          days: action.payload,
        },
      };

    case ActionTypesEnum.CHANGE_LIST:
      return {
        ...state,
        threatsList: action.payload
      };

    case ActionTypesEnum.CHANGE_TABLE:
      return {
        ...state,
        threatsTableData: action.payload
      };

    case ActionTypesEnum.CHANGE_CURRENT_IDS:
      return {
        ...state,
        currentIds: action.payload
      };

    case ActionTypesEnum.CHANGE_CURRENT:
      return {
        ...state,
        current: action.payload
      };

    case ActionTypesEnum.CHANGE_SITE_FORM_TYPE:
      return {
        ...state,
        forms: {
          ...state.forms,
          siteForm: {
            ...state.forms.siteForm,
            threatType: action.payload,
          }
        },
      };

    case ActionTypesEnum.CHANGE_SITE_FORM_STATUS:
      return {
        ...state,
        forms: {
          ...state.forms,
          siteForm: {
            ...state.forms.siteForm,
            status: action.payload,
          }
        },
      };

    case ActionTypesEnum.CHANGE_SITE_FORM_URL:
      return {
        ...state,
        forms: {
          ...state.forms,
          siteForm: {
            ...state.forms.siteForm,
            url: action.payload,
          }
        },
      };

    case ActionTypesEnum.CHANGE_SITE_FORM_SEARCH_DATE:
      return {
        ...state,
        forms: {
          ...state.forms,
          siteForm: {
            ...state.forms.siteForm,
            searchDate: action.payload,
          }
        },
      };
    case ActionTypesEnum.CHANGE_SITE_FORM_EVIDENCE_LINK:
      return {
        ...state,
        forms: {
          ...state.forms,
          siteForm: {
            ...state.forms.siteForm,
            evidenceLink: action.payload
          }
        },
      };
    case ActionTypesEnum.CHANGE_SITE_FORM_EVIDENCE_FILES:
      return {
        ...state,
        forms: {
          ...state.forms,
          siteForm: {
            ...state.forms.siteForm,
            evidence: action.payload
          }
        },
      };

    case ActionTypesEnum.CHANGE_SITE_FORM_IS_ACTIVE:
      return {
        ...state,
        forms: {
          ...state.forms,
          siteForm: {
            ...state.forms.siteForm,
            isActive: action.payload
          }
        },
      };
    // Profile Form Cases
    case ActionTypesEnum.CHANGE_PROFILE_FORM_EVIDENCE_LINK:
      return {
        ...state,
        forms: {
          ...state.forms,
          profileForm: {
            ...state.forms.profileForm,
            evidenceLink: action.payload
          }
        },
      };
    case ActionTypesEnum.CHANGE_PROFILE_FORM_EVIDENCE_FILES:
      return {
        ...state,
        forms: {
          ...state.forms,
          profileForm: {
            ...state.forms.profileForm,
            evidence: action.payload
          }
        },
      };
    case ActionTypesEnum.CHANGE_PROFILE_FORM_TYPE:
      return {
        ...state,
        forms: {
          ...state.forms,
          profileForm: {
            ...state.forms.profileForm,
            threatType: action.payload,
          }
        },
      };

    case ActionTypesEnum.CHANGE_PROFILE_FORM_STATUS:
      return {
        ...state,
        forms: {
          ...state.forms,
          profileForm: {
            ...state.forms.profileForm,
            status: action.payload,
          }
        },
      };

    case ActionTypesEnum.CHANGE_PROFILE_FORM_URL:
      return {
        ...state,
        forms: {
          ...state.forms,
          profileForm: {
            ...state.forms.profileForm,
            url: action.payload,
          }
        },
      };

    case ActionTypesEnum.CHANGE_PROFILE_FORM_SEARCH_DATE:
      return {
        ...state,
        forms: {
          ...state.forms,
          profileForm: {
            ...state.forms.profileForm,
            searchDate: action.payload,
          }
        },
      };

    case ActionTypesEnum.CHANGE_PROFILE_FORM_PROFILE:
      return {
        ...state,
        forms: {
          ...state.forms,
          profileForm: {
            ...state.forms.profileForm,
            profile: action.payload,
          }
        },
      };

    case ActionTypesEnum.CHANGE_PROFILE_FORM_PLATFORM:
      return {
        ...state,
        forms: {
          ...state.forms,
          profileForm: {
            ...state.forms.profileForm,
            platform: action.payload,
          }
        },
      };

    case ActionTypesEnum.CHANGE_PROFILE_FORM_IS_ACTIVE:
      return {
        ...state,
        forms: {
          ...state.forms,
          profileForm: {
            ...state.forms.profileForm,
            isActive: action.payload,
          }
        },
      };

    // Content Form Cases
    case ActionTypesEnum.CHANGE_CONTENT_FORM_EVIDENCE_LINK:
      return {
        ...state,
        forms: {
          ...state.forms,
          contentForm: {
            ...state.forms.contentForm,
            evidenceLink: action.payload
          }
        },
      };
    case ActionTypesEnum.CHANGE_CONTENT_FORM_EVIDENCE_FILES:
      return {
        ...state,
        forms: {
          ...state.forms,
          contentForm: {
            ...state.forms.contentForm,
            evidence: action.payload
          }
        },
      };
    case ActionTypesEnum.CHANGE_CONTENT_FORM_TYPE:
      return {
        ...state,
        forms: {
          ...state.forms,
          contentForm: {
            ...state.forms.contentForm,
            threatType: action.payload,
          }
        },
      };

    case ActionTypesEnum.CHANGE_CONTENT_FORM_STATUS:
      return {
        ...state,
        forms: {
          ...state.forms,
          contentForm: {
            ...state.forms.contentForm,
            status: action.payload,
          }
        },
      };

    case ActionTypesEnum.CHANGE_CONTENT_FORM_URL:
      return {
        ...state,
        forms: {
          ...state.forms,
          contentForm: {
            ...state.forms.contentForm,
            url: action.payload,
          }
        },
      };

    case ActionTypesEnum.CHANGE_CONTENT_FORM_CONTENT:
      return {
        ...state,
        forms: {
          ...state.forms,
          contentForm: {
            ...state.forms.contentForm,
            profile: action.payload,
          }
        },
      };

    case ActionTypesEnum.CHANGE_CONTENT_FORM_PLATFORM:
      return {
        ...state,
        forms: {
          ...state.forms,
          contentForm: {
            ...state.forms.contentForm,
            platform: action.payload,
          }
        },
      };

    case ActionTypesEnum.CHANGE_CONTENT_FORM_IS_ACTIVE:
      return {
        ...state,
        forms: {
          ...state.forms,
          contentForm: {
            ...state.forms.contentForm,
            isActive: action.payload,
          }
        },
      };


    // Ads Form Cases
    case ActionTypesEnum.CHANGE_ADS_FORM_TYPE:
      return {
        ...state,
        forms: {
          ...state.forms,
          adsForm: {
            ...state.forms.adsForm,
            threatType: action.payload,
          }
        },
      };
    case ActionTypesEnum.CHANGE_ADS_FORM_EVIDENCE_LINK:
      return {
        ...state,
        forms: {
          ...state.forms,
          adsForm: {
            ...state.forms.adsForm,
            evidenceLink: action.payload
          }
        },
      };
    case ActionTypesEnum.CHANGE_ADS_FORM_EVIDENCE_FILES:
      return {
        ...state,
        forms: {
          ...state.forms,
          adsForm: {
            ...state.forms.adsForm,
            evidence: action.payload
          }
        },
      };

    case ActionTypesEnum.CHANGE_ADS_FORM_STATUS:
      return {
        ...state,
        forms: {
          ...state.forms,
          adsForm: {
            ...state.forms.adsForm,
            status: action.payload,
          }
        },
      };

    case ActionTypesEnum.CHANGE_ADS_FORM_START_DATE:
      return {
        ...state,
        forms: {
          ...state.forms,
          adsForm: {
            ...state.forms.adsForm,
            startDate: action.payload,
          }
        },
      };

    case ActionTypesEnum.CHANGE_ADS_FORM_SEARCH_DATE:
      return {
        ...state,
        forms: {
          ...state.forms,
          adsForm: {
            ...state.forms.adsForm,
            searchDate: action.payload,
          }
        },
      };

    case ActionTypesEnum.CHANGE_ADS_FORM_ID:
      return {
        ...state,
        forms: {
          ...state.forms,
          adsForm: {
            ...state.forms.adsForm,
            id: action.payload,
          }
        },
      };

    case ActionTypesEnum.CHANGE_ADS_FORM_PLATFORM:
      return {
        ...state,
        forms: {
          ...state.forms,
          adsForm: {
            ...state.forms.adsForm,
            platform: action.payload,
          }
        },
      };

    case ActionTypesEnum.CHANGE_ADS_FORM_URL:
      return {
        ...state,
        forms: {
          ...state.forms,
          adsForm: {
            ...state.forms.adsForm,
            url: action.payload,
          }
        },
      };

    case ActionTypesEnum.CHANGE_ADS_FORM_INSTAGRAM_PROFILE:
      return {
        ...state,
        forms: {
          ...state.forms,
          adsForm: {
            ...state.forms.adsForm,
            instagramProfile: action.payload,
          }
        },
      };

    case ActionTypesEnum.CHANGE_ADS_FORM_ADVERTISER_NAME:
      return {
        ...state,
        forms: {
          ...state.forms,
          adsForm: {
            ...state.forms.adsForm,
            advertiserName: action.payload,
          }
        },
      };

    case ActionTypesEnum.CHANGE_ADS_FORM_ADVERTISER_ID:
      return {
        ...state,
        forms: {
          ...state.forms,
          adsForm: {
            ...state.forms.adsForm,
            advertiserId: action.payload,
          }
        },
      };

    case ActionTypesEnum.CHANGE_ADS_FORM_IS_ACTIVE:
      return {
        ...state,
        forms: {
          ...state.forms,
          adsForm: {
            ...state.forms.adsForm,
            isActive: action.payload,
          }
        },
      };

    case ActionTypesEnum.GET_FRAUDS_BY_COMPANY:
      return {
        ...state,
      };

    case ActionTypesEnum.REGISTER_FRAUD:
      return {
        ...state,
      };

    case ActionTypesEnum.ADD_FRAUD:

      return {
        ...state,
        threatsList: [action.payload, ...state.threatsList],
        forms: { ...ThreatInitialState.forms, formError: false }
      };

    case ActionTypesEnum.UPDATE_FRAUD:
      return {
        ...state,
      };

    case ActionTypesEnum.UPDATE_MULTIPLE_FRAUDS:
      return {
        ...state,
      };

    case ActionTypesEnum.RESTORE_FRAUD:
      return {
        ...state,
      };

    case ActionTypesEnum.RESTORE_MULTIPLE_FRAUDS:
      return {
        ...state,
      };

    case ActionTypesEnum.UPDATE_FRAUD_FROM_SAGA:
      var newState = action.payload.currentState
      var oldState = state.threatsList.find(el => el._id == action.payload._id)!.currentState

      return {
        ...state,
        threatsList: state.threatsList.map(el => {
          if (el._id == action.payload._id) return action.payload
          else return el
        }),
        fraudsQuantityByState: {
          ...state.fraudsQuantityByState,
          [newState]: (state.fraudsQuantityByState as any)[newState] + 1,
          [oldState]: (state.fraudsQuantityByState as any)[oldState] - 1 < 0 ? 0 : (state.fraudsQuantityByState as any)[oldState] - 1,
        }
      };

    case ActionTypesEnum.UPDATE_MULTIPLE_FRAUDS_FROM_SAGA:
      var newState = action.payload[0].currentState
      var oldState = state.threatsList.find(el => el._id == action.payload[0]._id)!.currentState

      return {
        ...state,
        threatsList: state.threatsList.map(threat => {
          if (action.payload.find((el: any) => el._id == threat._id)) return action.payload.find((el: any) => el._id == threat._id)
          else return threat
        }),
        fraudsQuantityByState: {
          ...state.fraudsQuantityByState,
          [newState]: (state.fraudsQuantityByState as any)[newState] + action.payload.length,
          [oldState]: (state.fraudsQuantityByState as any)[oldState] - action.payload.length < 0 ? 0 : (state.fraudsQuantityByState as any)[oldState] - action.payload.length,
        }
      };

    case ActionTypesEnum.CLEAN_FORMS:
      return {
        ...state,
        forms: ThreatInitialState.forms
      };
    case ActionTypesEnum.IS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case ActionTypesEnum.REGISTER_FRAUD_FAILURE:
      return {
        ...state,
        forms: {
          ...state.forms,
          formErrorMessage: action.payload,
          formError: true
        }
      };
    case ActionTypesEnum.CHANGE_FRAUDS_QUANTITY_BY_STATE:
      return {
        ...state,
        fraudsQuantityByState: action.payload
      };

    case ActionTypesEnum.CHANGE_FRAUDS_FILTERED_QUANTITY_BY_STATE:
      return {
        ...state,
        fraudsFilteredQuantityByState: action.payload
      };

    case ActionTypesEnum.CHANGE_FRAUDS_TOTAL_PAGES_BY_STATE:
      return {
        ...state,
        fraudsTotalPagesByState: action.payload
      };

    case ActionTypesEnum.CHANGE_FORM_ERROR:
      return {
        ...state,
        forms: {
          ...state.forms,
          formError: action.payload
        }
      };

    case ActionTypesEnum.CHANGE_SITE_FORM:
      return {
        ...state,
        forms: {
          ...state.forms,
          siteForm: action.payload
        }
      };

    case ActionTypesEnum.CHANGE_ADS_FORM:
      return {
        ...state,
        forms: {
          ...state.forms,
          adsForm: action.payload
        }
      };

    case ActionTypesEnum.CHANGE_PROFILE_FORM:
      return {
        ...state,
        forms: {
          ...state.forms,
          profileForm: action.payload
        }
      };

    case ActionTypesEnum.CHANGE_CONTENT_FORM:
      return {
        ...state,
        forms: {
          ...state.forms,
          contentForm: action.payload
        }
      };


    default:
      return state;
  }
};

export default threatReducer;
