import styled from "styled-components"
import { dsVariables } from "../../../variables"
import { AccessTime, Cancel, Check, DeleteOutline, Edit, EditOutlined, Restore, WebAssetOff } from "@mui/icons-material"
import { ThreatOptionsCardEnum } from "../../../../enums/threat-options-card.enum"
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/store.interface";
import useCanPerformAction from "../../../hooks/CanPerformAction.hook";
import { ActionEnum } from "../../../constants/user-action-permissions.constant";

interface IProps {
    onClick: any
    operatorView?: boolean
    tableIndex?: number
}

export default function OptionsCard({ onClick, operatorView = false, tableIndex }: IProps) {

    const searchParams = useSearchParams()[0]

    const tab = searchParams.get('tab')

    const { isCustomer, threatsTableData } = useSelector((store: IStore) => {
        return {
            isCustomer: store.Profile.user.isCustomer,
            threatsTableData: store.Threats.threatsTableData,
        }
    })

    const showApproveTakedown = useCanPerformAction(ActionEnum.approve_takedown)
    const showDiscardThreat = useCanPerformAction(ActionEnum.discard_threat)
    const showQuarantine = useCanPerformAction(ActionEnum.move_threat_to_quarantine)
    const showResolved = useCanPerformAction(ActionEnum.mark_threat_as_resolved)
    const showRestore = useCanPerformAction(ActionEnum.restore_threat_from_discarded)
    const showEdit = useCanPerformAction(ActionEnum.edit_threat)
    const showCancelApproval = useCanPerformAction(ActionEnum.cancel_approval_request)
    const showRequestTakedown = useCanPerformAction(ActionEnum.request_takedown_approval)

    return !isCustomer
        ? <Div tableLength={threatsTableData.length} tableIndex={tableIndex} operatorView={operatorView} className="d-flex flex-column gap-2 position-absolute">
            {(tab == '2' && showCancelApproval) && <button onClick={() => onClick(ThreatOptionsCardEnum.NEW)} className="d-flex gap-2 align-items-center bg-transparent border-0">
                <Cancel />
                <span>Cancelar solicitação de aprovação</span>
            </button>}
            {(tab != '4' && tab != '3' && (tab == '0' || !tab || tab == '1' ? showRequestTakedown : showApproveTakedown)) && <button onClick={() => onClick((tab == '0' || !tab || tab == '1') ? ThreatOptionsCardEnum.APPROVAL : ThreatOptionsCardEnum.TAKEDOWN)} className="d-flex gap-2 align-items-center bg-transparent border-0">
                <WebAssetOff />
                <span>{tab == '0' || !tab || tab == '1' ? 'Solicitar aprovação' : 'Aprovar takedown'}</span>
            </button>}
            {((tab == '0' || !tab) && showQuarantine) && <button onClick={() => onClick(ThreatOptionsCardEnum.QUARANTINE)} className="d-flex gap-2 align-items-center bg-transparent border-0">
                <AccessTime />
                <span>Quarentena</span>
            </button>}
            {(tab == '4' && showResolved) && <button onClick={() => onClick(ThreatOptionsCardEnum.RESOLVED)} className="d-flex gap-2 align-items-center bg-transparent border-0">
                <Check />
                <span>Marcar como resolvida</span>
            </button>}
            {(tab != '3' && showDiscardThreat) && <button onClick={() => onClick(ThreatOptionsCardEnum.DISCARD)} className="d-flex gap-2 align-items-center bg-transparent border-0">
                <DeleteOutline />
                <span>Descartar</span>
            </button>}
            {(tab == '3' && showRestore) && <button onClick={() => onClick(ThreatOptionsCardEnum.RESTORE)} className="d-flex gap-2 align-items-center bg-transparent border-0">
                <Restore />
                <span>Restaurar</span>
            </button>}
            {((tab == '0' || !tab || tab == '1' || tab == '4') && !operatorView && showEdit) && <button onClick={() => onClick(ThreatOptionsCardEnum.EDIT)} className="d-flex gap-2 align-items-center bg-transparent border-0">
                <EditOutlined />
                <span>Editar</span>
            </button>}
        </Div>
        : <Div tableLength={threatsTableData.length} tableIndex={tableIndex} operatorView={operatorView} className="d-flex flex-column gap-2 position-absolute">
            {(tab != '4' && (tab == '2' ? showApproveTakedown : showRequestTakedown)) && <button onClick={() => onClick(ThreatOptionsCardEnum.TAKEDOWN)} className="d-flex gap-2 align-items-center bg-transparent border-0">
                <WebAssetOff />
                <span>{tab == '2' ? 'Aprovar takedown' : 'Solicitar takedown'}</span>
            </button>}
            {(tab != '3' && showDiscardThreat) && <button onClick={() => onClick(ThreatOptionsCardEnum.DISCARD)} className="d-flex gap-2 align-items-center bg-transparent border-0">
                <DeleteOutline />
                <span>Descartar</span>
            </button>}
            {(tab != '1' && showQuarantine) && <button onClick={() => onClick(ThreatOptionsCardEnum.QUARANTINE)} className="d-flex gap-2 align-items-center bg-transparent border-0">
                <AccessTime />
                <span>Quarentena</span>
            </button>}
        </Div>
}

const Div = styled.div<{ operatorView?: boolean, tableIndex?: number, tableLength: number }>`
    background-color: ${dsVariables.colors.Gray[50]};
    ${props =>
        props.tableLength > 8 && (props.tableIndex == props.tableLength - 1 || props.tableIndex == props.tableLength - 2 || props.tableIndex == props.tableLength - 3) ?
            `
                bottom: 30px;
            `
            :
            `
                top: 30px;
            `
    };
    border-radius:${dsVariables.cornerRadius[4]};
    padding:${dsVariables.spacing[8]};
    ${props => !props.operatorView ? `
        right: 0;
        `
        : `
            width: 100%;
            margin-top:10px;
        `
    }
    box-shadow: 0px 0px 8px 0px #00000029;
    white-space:nowrap;
    z-index:2;

    button{
        color: ${dsVariables.colors.Gray[700]};
    
        &:hover {
            color: ${dsVariables.colors.Astronaut[900]};
        }
    }
`