//REGISTER
export const POST_REGISTER = "/user/register";

//LOGIN
export const POST_LOGIN = "/auth/login";
export const POST_PASSWORD_FORGET = "/user/send-password-reset";
export const POST_PASSWORD_RESET = "/user/reset-password";
export const SOCIAL_LOGIN = "/auth/google";

//PROFILE
export const GET_USER_PROFILE = "/user";
export const GET_USER_CAMPAIGN = "/campaign";
export const GET_USER_COMPANIES = "/company";
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/user";

//PANEL
export const GET_TOTAL_AGGRESSOR_FOUND = "/report/get-offenders";
export const GET_TOTAL_AGGRESSOR_NEW = "/report/get-offenders-new";
export const GET_TOTAL_AGGRESSOR_NOTIFIED = "/report/get-offender-notified";
export const GET_TOTAL_AGGRESSOR_MEDIATION = "/report/get-offender-mediation";
export const GET_TOTAL_AGGRESSOR_ELIMINATED = "/report/get-offender-eliminated";
export const GET_TOTAL_AGGRESSOR_INACTIVE = "/report/get-offender-inactive";
export const GET_TOTAL_AGGRESSOR_REAPPEARED = "/report/get-offender-reappeared";
export const GET_OFFENDER_RANGE_DATE = "/report/get-offender-range-date";
export const GET_OCURRENCES = "/report/get-ocurrences";
export const GET_BRANDSCORE = "/report/brand-score/";

//REPORT
export const GET_CAMPAIGN_TOTAL_REPORT = "/report/get-offenders-detail";
export const GET_CAMPAIGN_NEW_REPORT = "/report/get-offenders-new-detail";
export const GET_CAMPAIGN_REAPPEARED_REPORT =
  "/report/get-offenders-reappeared-detail";
export const GET_CAMPAIGN_NOTIFIED_REPORT =
  "/report/get-offenders-notified-detail";
export const GET_CAMPAIGN_MEDIATION_REPORT =
  "/report/get-offenders-mediation-detail";
export const GET_CAMPAIGN_ELIMINATED_REPORT =
  "/report/get-offenders-eliminated-detail";

//EVENT
export const GET_EVENT = (eventId, aggressivenessIndex) => {
  let url = `/report/get-event/${eventId}`;
  if (aggressivenessIndex !== undefined) {
    url += `?aggressivenessIndex=${aggressivenessIndex}`;
  }
  return url;
};
export const GET_EVENT_ADS = "/event/event-ads";
export const PATCH_EVENT_MEDIATION = (eventId) =>
  `/event/${eventId}/update-mediation`;
export const POST_EVENT_EVIDENCE_UPLOAD = (eventId) =>
  `/event/${eventId}/upload-evidence`;
export const DELETE_EVENT_EVIDENCE = (eventId) => `/event/${eventId}/evidence`;

//ANALYTICAL REPORT
export const GET_DAILY_SCORES = "/daily-scores/get-daily-scores";
export const GET_DAILY_TOTAL_AGGRESSION =
  "/daily-scores/get-daily-total-aggression";
export const GET_ANALYTICAL_KPIS = "/report/get-analytical-kpis";
export const GET_OFFENDERS_GROUP_BY_DOMAIN =
  "/daily-scores/get-offenders-group-by-domain";
export const GET_OCCURRENCES_TREND = "/daily-scores/get-occurrences-trend";
export const GET_BRAND_SCORES = "/report/get-brand-scores";

// FRAUDS
export const FRAUDS = "/fraud";
export const PATCH_MULTIPLE_FRAUDS = "/fraud/multiple";
export const RESTORE_MULTIPLE_FRAUDS = "/fraud/multiple-restore";
export const RESTORE_FRAUD = "/fraud/restore";

// COMPANY PRODUCTS
export const COMPANY_PRODUCTS = "company-products"

// ADMIN
export const GET_COMPANIES_TO_ADMIN = "/company/admin";
export const SEND_USER_INVITE = "/user/send-user-invite";
export const CHANGE_USER_PERMISSIONS = "/user/change-permissions";
export const GET_USER_BY_EMAIL = "/user/get-by-email";
export const GET_ALL_USERS = "/user/list-all";