import styled from "styled-components"
import { dsVariables } from "../variables"
import Icon from "./Icon"
import { Close, DeleteOutline } from "@mui/icons-material"

type TType = 'default' | 'clean_all'

interface IProps {
    type?: TType
    label: string
    onClick:any
}

export default function Chip({ label, type = 'default',onClick }: IProps) {
    return (
        <Div role="button" onClick={onClick} className="d-flex gap-1 align-items-center justify-content-center" type={type}>
            <Span type={type}>{label}</Span>
            <Bar type={type}></Bar>
            <Icon height={16} width={16} color={type == 'default' ? dsVariables.colors.Astronaut[900] : dsVariables.colors.Red[900]} IconComponent={type == 'default' ? Close : DeleteOutline} />
        </Div>
    )
}

const Bar = styled.div<{ type: TType }>`
    width: 1px;
    height:12px;
    border-radius:4px;

    ${props => {
        switch (props.type) {
            case "default": return `
                background-color: ${dsVariables.colors.Astronaut[300]};
            `
            case "clean_all": return `
                background-color: ${dsVariables.colors.Red[300]};
            `
        }
    }}
`

const Div = styled.div<{ type: TType }>`
    border-width:1px;
    border-style:solid;
    border-radius: ${dsVariables.cornerRadius[8]};
    padding:${dsVariables.spacing[4]};

    ${props => {
        switch (props.type) {
            case "default": return `
                background-color: ${dsVariables.colors.Astronaut[100]};
                border-color: ${dsVariables.colors.Astronaut[300]};
                color: ${dsVariables.colors.Astronaut[900]};
            `
            case "clean_all": return `
                background-color: ${dsVariables.colors.Red[100]};
                border-color: ${dsVariables.colors.Red[300]};
                color: ${dsVariables.colors.Red[900]};
            `
        }
    }}
`

const Span = styled.span<{ type: TType }>`
    font-size:10px;
`