import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from 'redux-persist';
import createFilter from 'redux-persist-transform-filter';

import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers";
import storage from 'redux-persist/lib/storage'
import config from "../config";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware,];

const composeEnhancers =
  (config.mode !== 'prod' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const profileFilter = createFilter(
  'Profile',
  ['selectedCompany']
);

const persistConfig = {
  key: 'bm-platform',
  whitelist: ['Profile'],
  keyPrefix: "",
  storage,
  transforms: [profileFilter],
};


const persistedReducer = persistReducer(persistConfig, rootReducer);
let store = createStore(persistedReducer, {}, composeEnhancers(applyMiddleware(...middlewares)));
let persistor = persistStore(store);


export { store, persistor, sagaMiddleware };