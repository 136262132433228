export const SET_PANEL_CAMPAIGNID = "SET_PANEL_CAMPAIGNID";
export const GET_TOTAL_AGGRESSOR_FOUND = "GET_TOTAL_AGGRESSOR_FOUND";
export const GET_TOTAL_AGGRESSOR_NEW = "GET_TOTAL_AGGRESSOR_NEW";
export const GET_TOTAL_AGGRESSOR_REAPPEARED = "GET_TOTAL_AGGRESSOR_REAPPEARED";
export const GET_TOTAL_AGGRESSOR_NOTIFIED = "GET_TOTAL_AGGRESSOR_NOTIFIED";
export const GET_TOTAL_AGGRESSOR_MEDIATION = "GET_TOTAL_AGGRESSOR_MEDIATION";
export const GET_TOTAL_AGGRESSOR_ELIMINATED = "GET_TOTAL_AGGRESSOR_ELIMINATED";
export const GET_TOTAL_AGGRESSOR_INACTIVE = "GET_TOTAL_AGGRESSOR_INACTIVE";
export const SET_WIDGET_LOADING = "SET_WIDGET_LOADING";
export const SET_COUNTER_SUCCESS = "SET_COUNTER_SUCCESS";
export const SET_PANEL_LOADING = "SET_PANEL_LOADING";
export const REMOVE_PANEL_LOADING = "REMOVE_PANEL_LOADING";
export const WIDGET_API_ERROR = "WIDGET_API_ERROR";
export const GET_LINE_AGGRESSORS = "GET_LINE_AGGRESSORS";
export const SET_LINE_AGGRESSORS_SUCCESS = "SET_LINE_AGGRESSORS_SUCCESS";
export const SET_LINE_LOADING = "SET_LINE_LOADING";
export const LINE_API_ERROR = "LINE_API_ERROR";
export const GET_TOP_AGGRESSORS = "GET_TOP_AGGRESSORS";
export const SET_TOP_AGGRESSORS_LOADING = "SET_TOP_AGGRESSORS_LOADING";
export const SET_TOP_AGGRESSORS_SUCCESS = "SET_TOP_AGGRESSORS_SUCCESS";
export const SET_TOP_AGGRESSORS_ERROR = "SET_TOP_AGGRESSORS_ERROR";
export const GET_BRANDSCORE = "GET_BRANDSCORE";
export const SET_BRANDSCORE = "SET_BRANDSCORE";
export const SET_BRANDSCORE_LOADING = "SET_BRANDSCORE_LOADING";
export const SET_BRANDSCORE_ERROR = "SET_BRANDSCORE_ERROR";
export const GET_TOTAL_ELIMINATED = "GET_TOTAL_ELIMINATED";
export const SET_TOTAL_ELIMINATED = "SET_TOTAL_ELIMINATED";
export const SET_TOTAL_ELIMINATED_LOADING = "SET_TOTAL_ELIMINATED_LOADING";
export const SET_TOTAL_ELIMINATED_ERROR = "SET_TOTAL_ELIMINATED_ERROR";
export const RESET_PANEL = "RESET_PANEL";
