import { Form, FormGroup, FormLabel, FormSelect } from "react-bootstrap";
import UserDropdownAdmin from "../user-dropdown-admin";
import CompaniesDropdownAdmin from "../companies-dropdown-admin";
import ButtonPrimary from "../../../components/Button/ButtonPrimary";
import { Bounce, toast } from "react-toastify";
import { permissionCategory } from "../../../constants/permission-category.constant";
import { RolesEnum } from "../../../../enums/roles.enum";
import { changeUserPermissions, fetchUsers, getAllCompaniesToAdmin, getCompaniesGroup, getUserToChangePermissions } from "../../../../store/admin/action";
import { setLoading } from "../../../../store/threats/actions";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/store.interface";
import { useEffect, useState } from "react";
import useCanPerformAction from "../../../hooks/CanPerformAction.hook";
import { ActionEnum } from "../../../constants/user-action-permissions.constant";

export default function ChangePermissionForm() {

    const dispatch = useDispatch()

    const { companiesGroup, user, currentUser, users, companies } = useSelector((store: IStore) => ({
        companiesGroup: store.Admin.companiesGroupList,
        user: store.Admin.user,
        currentUser: store.Profile.user,
        users: store.Admin.users,
        companies: store.Admin.companiesList,
    }))

    const [selectedUser, setSelectedUser] = useState('');
    const [selectedCompany, setSelectedCompany] = useState('');
    const [userType, setUserType] = useState<'customer' | 'operator' | 'agency'>();
    const [companiesSelected, setCompaniesSelected] = useState<string[]>([]);
    const [roles, setRoles] = useState<any[]>([]);

    const canInviteOperator = useCanPerformAction(ActionEnum.invite_agents)
    const canInviteCustomer = useCanPerformAction(ActionEnum.invite_customers)
    const canEdit = useCanPerformAction(ActionEnum.edit_permissions)

    function handleSubmit(e: any) {
        e.preventDefault()
        const email = users?.find(el => el._id == selectedUser)?.email

        if (userType != 'operator' && companiesSelected.length == 0) return toast('Seleciona ao menos uma empresa', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            transition: Bounce,
            type: 'warning'
        })

        if (currentUser.email == email) return toast('Não é possível alterar o seu próprio usuário', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            transition: Bounce,
            type: 'warning'
        })

        if (userType == 'operator' && !(currentUser.email.includes('branddi') || currentUser.email.includes('brandmonitor'))) {
            return toast(`Você não tem permissão para alterar um Agente Branddi`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                transition: Bounce,
                type: 'warning'
            })
        }

        if (!roles.length) return toast('Insira ao menos uma permissão', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            transition: Bounce,
            type: 'warning'
        })

        const categoriesByPermission = roles.map((el: any) => ({
            category: el.category,
            permission: el.name
        }))

        dispatch(setLoading(true))

        dispatch(changeUserPermissions({
            email,
            categoriesByPermission,
            customerCompaniesList: userType == 'operator' ? [] : companiesSelected
        }))
    }

    function handleChangeOperatorPermission(e: any) {
        const permission = e.target.value
        const { checked } = e.target

        if (!checked) return setRoles(roles?.filter(el => el.name != permission))

        // @ts-ignore
        const currentPermissionCategory = [...document.getElementsByName('operator_category')].find(el => el.id == permission).value

        if (permission == RolesEnum.COMMERCIAL || permission == RolesEnum.ADMIN || permission == RolesEnum.MANAGER)
            return setRoles([{ name: permission, category: currentPermissionCategory }])

        const updatedRoles = roles.filter(el => el.name != RolesEnum.COMMERCIAL && el.name != RolesEnum.ADMIN && el.name != RolesEnum.MANAGER)

        updatedRoles.push({ name: permission, category: currentPermissionCategory })
        setRoles(updatedRoles)
    }

    function handleChangeOperatorCategory(e: any) {
        const category = e.target.value
        const permission = e.target.id

        const updatedRoles = roles.map(el => {
            if (el.name == permission) return { ...el, category }
            return el
        })

        setRoles(updatedRoles)
    }

    function handleCompanyGroup(id: string) {
        if (companiesSelected?.find(el => el == id)) setCompaniesSelected(companiesSelected.filter(el => el != id))
        else {
            if (isGroup(id)) setCompaniesSelected([id])
            else if (hasGroupSelected()) {
                const groupId = companiesSelected?.find(el => el == companiesGroup?.find(cp => cp?.isGroup)?._id)
                const companiesListUpdated = companiesSelected?.filter(el => el != groupId)
                setCompaniesSelected(companiesListUpdated)
            }
            else setCompaniesSelected([...companiesSelected, id])
        }
    }

    function hasGroupSelected() {
        return Boolean(companiesSelected?.find(el => el == companiesGroup?.find(cp => cp?.isGroup)?._id))
    }

    function isGroup(currentCompanyId: string) {
        return currentCompanyId == companiesSelected?.find(el => el == companiesGroup?.find(cp => cp?.isGroup)?._id)
    }

    function updateCompaniesSelected(id: string) {
        if (companiesSelected?.find(el => el == id)) setCompaniesSelected(companiesSelected.filter(el => el != id))
        else setCompaniesSelected([...companiesSelected, id])
    }

    function handleCompany(e: any) {
        setCompaniesSelected([])
        dispatch(getCompaniesGroup(e.target.value))
        setSelectedCompany(e.target.value)
    }

    useEffect(() => {
        setUserType(undefined)
        setRoles([])
        dispatch(fetchUsers())
    }, []);

    useEffect(() => {
        selectedUser && dispatch(getUserToChangePermissions(users?.find(el => el._id == selectedUser)?.email))
    }, [selectedUser]);

    useEffect(() => {
        if (users.length > 0 && !selectedUser) {
            dispatch(getUserToChangePermissions(users[0].email))
            setSelectedUser(users[0]._id)
        }
    }, [users]);

    useEffect(() => {
        if (user) {
            setUserType(user.roles?.find((el: any) => el.category == 'customer') ? 'customer' : 'operator')
            setRoles(user.roles)
            console.log(user.roles)
            if (user.customerCompaniesList) {
                dispatch(getCompaniesGroup(user.customerCompaniesList[0]))
                setCompaniesSelected(user.customerCompaniesList)
                setSelectedCompany(user.customerCompaniesList[0])
            }
        }
    }, [user]);

    useEffect(() => {
        if (companies.length == 0) dispatch(getAllCompaniesToAdmin())
        else dispatch(getCompaniesGroup(companies[0]._id))
    }, [companies]);

    useEffect(() => {
        if (companiesGroup.length == 1) setCompaniesSelected([...companiesSelected, companiesGroup[0]?._id])
    }, [companiesGroup]);

    useEffect(() => {
        setCompaniesSelected([])
        setRoles([])
        setSelectedCompany('')
    }, [userType]);

    return (
        <Form onSubmit={handleSubmit} className="d-flex flex-column gap-4 mt-5">
            <FormGroup>
                <FormLabel className="text-black fs-5">Email</FormLabel>
                <div className="d-flex gap-2">
                    <UserDropdownAdmin selectedUser={selectedUser} handleUser={setSelectedUser} />
                </div>
            </FormGroup>
            {user && <FormGroup>
                <FormLabel className="text-black fs-5">Selecionar tipo de usuário</FormLabel>
                <div className="d-flex gap-4">
                    {canInviteCustomer && <div className="d-flex gap-2">
                        <FormLabel className="mb-0">Cliente</FormLabel>
                        <input checked={userType == 'customer'} required onChange={_ => setUserType('customer')} name="user_type" type="radio" />
                    </div>}
                    {canInviteOperator && <div className="d-flex gap-2">
                        <FormLabel className="mb-0">Agente Branddi</FormLabel>
                        <input checked={userType == 'operator'} required onChange={_ => setUserType('operator')} name="user_type" type="radio" />
                    </div>}
                    {canInviteCustomer && <div className="d-flex gap-2">
                        <FormLabel className="mb-0">Agência</FormLabel>
                        <input required onChange={_ => setUserType('agency')} name="user_type" type="radio" />
                    </div>}
                </div>
            </FormGroup>}
            {(userType != 'operator' && user) && <FormGroup>
                <FormLabel className="text-black fs-5">Selecionar permissões do cliente</FormLabel>
                <div className="d-flex gap-4">
                    <div className="d-flex gap-2">
                        <FormLabel className="mb-0">Visualização</FormLabel>
                        <input onChange={e => setRoles([{ category: 'customer', name: e.target.value }])} checked={roles?.find((el: any) => el.name == RolesEnum.CUSTOMER_VIEW)} required type="radio" name="customer_permission" value={RolesEnum.CUSTOMER_VIEW} />
                    </div>
                    <div className="d-flex gap-2">
                        <FormLabel className="mb-0">Triagem</FormLabel>
                        <input onChange={e => setRoles([{ category: 'customer', name: e.target.value }])} checked={roles?.find((el: any) => el.name == RolesEnum.CUSTOMER_TRIAGE)} required type="radio" name="customer_permission" value={RolesEnum.CUSTOMER_TRIAGE} />
                    </div>
                    <div className="d-flex gap-2">
                        <FormLabel className="mb-0">Admin</FormLabel>
                        <input onChange={e => setRoles([{ category: 'customer', name: e.target.value }])} checked={roles?.find((el: any) => el.name == RolesEnum.CUSTOMER_ADMIN)} required type="radio" name="customer_permission" value={RolesEnum.CUSTOMER_ADMIN} />
                    </div>
                </div>
            </FormGroup>}
            {userType == 'operator' && <FormGroup>
                <FormLabel className="text-black fs-5">Selecionar permissões do agente interno</FormLabel>
                <div className="d-flex gap-5 flex-wrap">
                    {Object.values(RolesEnum).filter(el => !el.includes('Cliente')).map((el, index) =>
                        <div key={index} className="d-flex gap-2 flex-column">
                            <div className="d-flex gap-2">
                                <FormLabel className="mb-0">{el}</FormLabel>
                                <input checked={Boolean(roles?.find((role: any) => role.name == el))} onChange={handleChangeOperatorPermission} type="checkbox" name="operator_permission" value={el} />
                            </div>
                            <FormSelect onChange={handleChangeOperatorCategory} value={roles?.find((role: any) => role.name == el && role.category == Object.values(permissionCategory).find(el => el.value == role.category)?.value)?.category} id={el} name="operator_category">
                                {Object.values(permissionCategory).map((el, index) => <option key={index} value={el.value}>{el.text}</option>)}
                            </FormSelect>
                        </div>)}
                </div>
            </FormGroup>}
            {(userType && userType != 'operator') &&
                <FormGroup>
                    <FormLabel className="text-black fs-5">Selecionar empresas</FormLabel>
                    <CompaniesDropdownAdmin companiesSelected={companiesSelected} updateCompaniesSelected={updateCompaniesSelected} userType={userType} selectedCompany={selectedCompany} handleCompany={handleCompany} />
                    {(userType == 'agency' && Boolean(companiesSelected.length)) && <p className="mt-2 fw-semibold"><span className="text-secondary">{companiesSelected.length}</span> empresas selecionadas</p>}
                </FormGroup>
            }
            {(userType && userType == 'customer' && companiesGroup?.length > 0) &&
                <FormGroup>
                    <FormLabel className="text-black fs-5">Selecionar empresas do mesmo grupo</FormLabel>
                    <div className="d-flex flex-column gap-2">
                        {companiesGroup?.map((el, index) =>
                            <div onClick={() => handleCompanyGroup(el._id)}
                                role={(!hasGroupSelected() || isGroup(el._id)) ? "button" : "none"}
                                className={`form-control d-flex gap-2
                                        ${companiesSelected.length > 0 ?
                                        hasGroupSelected()   // Primeira verificação para saber se existe alguma empresa que é 'grupo' nos selecionados
                                            ? isGroup(el._id) // Segunda verificação para saber se a empresa atual é 'grupo'
                                                ? 'bg-primary text-white'
                                                : 'bg-dark text-white pe-none opacity-50'
                                            : companiesSelected?.find(cp => cp == el._id) // Terceira verificação para estilizar a empresa selecionada
                                                ? 'bg-primary text-white'
                                                : companiesGroup?.find(cp => cp?.isGroup && cp._id == el._id) // Quarta verificação para travar a empresa 'grupo'
                                                    ? 'bg-dark text-white pe-none opacity-50'
                                                    : ''
                                        : ''
                                    }
                                    `} key={index}>
                                <input className="pe-none" checked={Boolean(companiesSelected?.find(cp => cp == el._id))} type="checkbox" name="" id="" />
                                <span>{el.name}</span>
                            </div>
                        )}
                    </div>
                </FormGroup>
            }

            {(canInviteCustomer || canInviteOperator || canEdit) && <div className="d-flex">
                <ButtonPrimary isSubmit label="Enviar" />
            </div>}
        </Form>
    )
}