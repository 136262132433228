import { useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "react-bootstrap";
import { dsVariables } from "../../variables";
import { useSelector } from "react-redux";
import { IStore } from "../../../interfaces/store.interface";
import { Input } from "reactstrap";
import SimpleBar from "simplebar-react";

export default function UserDropdownAdmin({ selectedUser, handleUser }: any) {
    const { users } = useSelector((store: IStore) => ({
        users: store.Admin.users,
    }))

    const [userFilterByName, setUserFilterByName] = useState("");

    const filteredAndSortedUsers = users?.slice().sort((a, b) => {
        // A empresa selecionada fica no topo da lista.
        if (a.email === users.find(el => el._id == selectedUser)?.email) return -1;
        if (b.email === users.find(el => el._id == selectedUser)?.email) return 1;
        return a.email.localeCompare(b.email);
    }).filter(el =>
        !userFilterByName ||
        new RegExp(userFilterByName, "i").test(el.email))

    const handleSelectedUserFilter = (value: string) => {
        setUserFilterByName(value);
    };

    const label = users.find(el => el._id == selectedUser)?.fullName
        ? users.find(el => el._id == selectedUser)?.fullName + ' - ' + users.find(el => el._id == selectedUser)?.email
        : users[0]?.fullName + ' - ' + users[0]?.email

    return (
        <Dropdown className="w-100">
            <DropdownToggle
                style={{ color: dsVariables.colors.Astronaut[400] }}
                className="form-control text-start bg-white text-black"
            >
                <span>{label}</span>
            </DropdownToggle>

            <DropdownMenu className="form-control">
                <div className="py-3 px-3 border-top-0 border-start-0 border-end-0 border-dashed border">
                    <div className="form-icon">
                        <Input
                            className="form-control form-control-icon"
                            id="searchBrands"
                            placeholder="Pesquisar nome da empresa"
                            onChange={(event) =>
                                handleSelectedUserFilter(event.target.value)
                            }
                            value={userFilterByName}
                        />
                        <i className="ri-search-line"></i>
                    </div>
                </div>

                <SimpleBar style={{ maxHeight: "260px" }}>
                    {
                        filteredAndSortedUsers?.map((user, index) =>
                            user._id === selectedUser ?
                                <DropdownItem
                                    defaultValue={user?.customerUsersList && user?.customerUsersList[0]}
                                    className="notify-item bg-primary text-white" key={index}
                                    onClick={(e) => handleUser(user._id)}
                                >
                                    <span className="align-middle">{user.fullName} - {user.email}</span>
                                </DropdownItem>
                                :
                                <DropdownItem
                                    className="notify-item"
                                    key={index}
                                    id={user._id}
                                    onClick={(e) => handleUser(user._id)}
                                >
                                    <span className="align-middle">{user.fullName} - {user.email}</span>
                                </DropdownItem>
                        )
                    }
                </SimpleBar>
            </DropdownMenu>
        </Dropdown >
    )
}