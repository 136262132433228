export enum PermissionCategoryValueEnum {
    BLACK_1 = "Black 1",
    BLACK_2 = "Black 2",
    TITANIUM_1 = "Titanium 1",
    TITANIUM_2 = "Titanium 2",
    PLATINUM_1 = "Platinum 1",
    PLATINUM_2 = "Platinum 2",
    EVERYTHING = "*",
}

export enum PermissionCategoryTextEnum {
    BLACK_1 = "Black 1",
    BLACK_2 = "Black 2",
    TITANIUM_1 = "Titanium 1",
    TITANIUM_2 = "Titanium 2",
    PLATINUM_1 = "Platinum 1",
    PLATINUM_2 = "Platinum 2",
    EVERYTHING = "Todos",
}