import React from "react";
import CountUp from "react-countup";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Fade,
  Progress,
  Row,
  Spinner,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { SemiCircularRadial } from "./RadialbarCharts";
import { TABS_MAP } from "../../../utils/tabsMap";

import BouncingDotsLoader from "../../../Components/Common/BoucingDotsLoader";
import { useDispatch } from "react-redux";
import { setTab } from "../../../store/pages/reports/actions";
import moment from "moment-timezone";

const DAYS_14 = 14;
const startDate = moment().subtract(DAYS_14, "days").startOf("day").toDate();
const endDate = moment()
  .subtract(1, "days")
  .endOf("day")
  .tz("America/Sao_Paulo")
  .toDate();

export const Widgets = ({
  widgets,
  selectedCampaign,
  selectedCompany,
  panelLoading,
  brandScore,
  brandScoreLoading,
  totalEliminated,
  brandScoreError,
  totalEliminatedLoading,
}) => {
  const navigate = useNavigate();

  const brandScoreRanges = [
    { bg: "low-brandscore", min: 0, max: 25 },
    { bg: "regular-brandscore", min: 26, max: 49 },
    { bg: "medium-brandscore", min: 50, max: 89 },
    { bg: "high-brandscore", min: 90, max: 99 },
    { bg: "max-brandscore", min: 100, max: 100 },
  ];

  const bgBrandScoreClass = (brandScore) => {
    let bg;

    brandScoreRanges.forEach((range) => {
      if (
        Math.round(brandScore) <= range.max &&
        Math.round(brandScore) >= range.min
      ) {
        bg = range.bg;
      }
    });

    return bg;
  };

  const dispatch = useDispatch();

  const searchParams = new URLSearchParams();
  searchParams.set("companyId", selectedCompany.value);
  searchParams.set("campaignId", selectedCampaign.value);
  searchParams.set("startDate", startDate);
  searchParams.set("endDate", endDate);
  searchParams.set("days", DAYS_14);
  searchParams.set("agressor", "");
  searchParams.set("channel", "");
  searchParams.set("actualPhase", "");
  searchParams.set("notify", "");
  searchParams.set("keyword", "");
  searchParams.set("page", "");
  searchParams.set("position", "");
  searchParams.set("paginationIndex", "0");

  const handleTab = (tab) => {
    dispatch(setTab(tab));
    const tabKey = Object.keys(TABS_MAP).find((key) => TABS_MAP[key] === tab);
    if (tabKey) {
      console.log(tabKey);
      searchParams.set("tab", tabKey);
      const reportsLink = `/reports/?${searchParams.toString()}`;
      navigate(reportsLink);
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={5} xl={4} className="d-flex align-self-stretch flex-fill">
          <Card className="mb-0 w-100">
            <CardBody className="text-center pr-0 d-flex justify-content-center align-items-center">
              {panelLoading ? (
                <BouncingDotsLoader />
              ) : selectedCampaign.urlLogo ? (
                <div
                  className="widget-company-logo"
                  style={{
                    backgroundImage: `url( ${selectedCampaign.urlLogo} )`,
                  }}
                />
              ) : (
                <h1 className="text-center">{selectedCampaign.label}</h1>
              )}
            </CardBody>
          </Card>
        </Col>

        <Col lg={7} xl={8} className="d-flex align-self-stretch flex-fill">
          <Card className="mb-0 w-100 brand-score-card">
            <Fade in={!brandScoreLoading}>
              <CardBody className={`${bgBrandScoreClass(brandScore)} p-0`}>
                <div className="brand-score-card__body">
                  <span className="brand-score-card__body__title">
                    Seu Branddi Score
                  </span>
                  <div className="brand-score-card__body__bg-chart">
                    <div></div>
                  </div>
                  <SemiCircularRadial
                    brandScore={brandScore}
                    brandScoreLoading={brandScoreLoading}
                    brandScoreError={brandScoreError}
                    panelLoading={panelLoading}
                  />
                </div>
                <div className="brand-score-card__footer">
                  {totalEliminatedLoading ? (
                    <span className="w-50 align-items-center mt-1">
                      <BouncingDotsLoader size={"4px"} />
                    </span>
                  ) : (
                    <Fade in={!totalEliminatedLoading}>
                      {totalEliminated !== 0 ? (
                        <span className="m-0 text-primary">
                          Parabéns, você já <strong>eliminou</strong> um total de:{" "}
                          <strong>{totalEliminated} agressores!</strong>
                        </span>
                      ) : (
                        <span>Nenhum agressor encontrado no período.</span>
                      )}
                    </Fade>
                  )}
                </div>

              </CardBody>
            </Fade>
          </Card>
        </Col>
      </Row>

      <Alert color="warning" className="mt-3">
        <strong> Agressores da sua marca </strong> nos últimos 14 dias:
      </Alert>

      <Row className="g-2 mb-4">
        <Col xs={6} lg={4} className="d-flex align-self-stretch flex-fill">
          <Card className="card-animate mb-0 w-100">
            <CardBody>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="text-muted mb-0 widget-card-title">
                    IDENTIFICADOS
                  </p>
                  <h2 className="mt-3 mb-2 ff-secondary fw-semibold">
                    <span className="counter-value" data-target="3">
                      {widgets.foundAgressors.loading || panelLoading ? (
                        <Spinner color="primary" />
                      ) : (
                        <CountUp
                          start={0}
                          end={
                            !widgets.foundAgressors.error &&
                            widgets.foundAgressors.counter
                          }
                          duration={4}
                        />
                      )}
                    </span>
                  </h2>
                </div>
                <div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title rounded fs-3 bg-danger-subtle">
                      <i className="bx bxs-mask text-danger"></i>
                    </span>
                  </div>
                </div>
              </div>
              <p
                onClick={() => handleTab("1")}
                className="block link-primary text-decoration-underline fs-12 cursor-pointer m-0"
              >
                Ver relatório completo
              </p>
            </CardBody>
          </Card>
        </Col>

        <Col xs={6} lg={4} className="d-flex align-self-stretch flex-fill">
          <Card className="card-animate mb-0 w-100">
            <CardBody>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="text-muted mb-0 widget-card-title">NOVOS</p>
                  <h2 className="mt-3 mb-2 ff-secondary fw-semibold">
                    <span className="counter-value" data-target="3">
                      {widgets.newAgressors.loading || panelLoading ? (
                        <Spinner color="primary" />
                      ) : (
                        <CountUp
                          start={0}
                          end={
                            !widgets.newAgressors.error &&
                            widgets.newAgressors.counter
                          }
                          duration={4}
                        />
                      )}
                    </span>
                  </h2>
                </div>
                <div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title rounded fs-3 bg-danger-subtle">
                      <i className="bx bx-alarm-exclamation text-danger"></i>
                    </span>
                  </div>
                </div>
              </div>
              <p
                onClick={() => handleTab("2")}
                className="block link-primary text-decoration-underline fs-12 cursor-pointer m-0"
              >
                Ver novos agressores
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col xs={6} lg={4} className="d-flex align-self-stretch flex-fill">
          <Card className="card-animate mb-0 w-100">
            <CardBody>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="text-muted mb-0 widget-card-title">
                    REINCIDENTES
                  </p>
                  <h2 className="mt-3 mb-2 ff-secondary fw-semibold">
                    <span className="counter-value" data-target="3">
                      {widgets.reappearedAgressors.loading || panelLoading ? (
                        <Spinner color="primary" />
                      ) : (
                        <CountUp
                          start={0}
                          end={
                            !widgets.reappearedAgressors.error &&
                            widgets.reappearedAgressors.counter
                          }
                          duration={4}
                        />
                      )}
                    </span>
                  </h2>
                </div>
                <div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title rounded fs-3 bg-danger-subtle">
                      <i className="bx bx-analyse text-danger"></i>
                    </span>
                  </div>
                </div>
              </div>
              <p
                onClick={() => handleTab("3")}
                className="block link-primary text-decoration-underline fs-12 cursor-pointer m-0"
              >
                Ver agressores reincidentes
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col xs={6} lg={4} className="d-flex align-self-stretch flex-fill">
          <Card className="card-animate mb-0 w-100">
            <CardBody>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="text-muted mb-0 widget-card-title">
                    NOTIFICADOS
                  </p>
                  <h2 className="mt-3 mb-2 ff-secondary fw-semibold">
                    <span className="counter-value" data-target="3">
                      {widgets.notifiedAgressors.loading || panelLoading ? (
                        <Spinner color="primary" />
                      ) : (
                        <CountUp
                          start={0}
                          end={
                            !widgets.notifiedAgressors.error &&
                            widgets.notifiedAgressors.counter
                          }
                          duration={4}
                        />
                      )}
                    </span>
                  </h2>
                </div>
                <div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title rounded fs-3 bg-warning-subtle">
                      <i className="bx bx-mail-send text-warning"></i>
                    </span>
                  </div>
                </div>
              </div>
              <p
                onClick={() => handleTab("4")}
                className="block link-primary text-decoration-underline fs-12 cursor-pointer m-0"
              >
                Ver agressores notificados
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col xs={6} lg={4} className="d-flex align-self-stretch flex-fill">
          <Card className="card-animate mb-0 w-100">
            <CardBody>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="text-muted mb-0 widget-card-title">
                    EM MEDIAÇÃO
                  </p>
                  <h2 className="mt-3 mb-2 ff-secondary fw-semibold">
                    <span className="counter-value" data-target="3">
                      {widgets.inMediationAgressors.loading || panelLoading ? (
                        <Spinner color="primary" />
                      ) : (
                        <CountUp
                          start={0}
                          end={
                            !widgets.inMediationAgressors.error &&
                            widgets.inMediationAgressors.counter
                          }
                          duration={4}
                        />
                      )}
                    </span>
                  </h2>
                </div>
                <div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title rounded fs-3 bg-warning-subtle">
                      <i className="bx bx-hourglass text-warning"></i>
                    </span>
                  </div>
                </div>
              </div>
              <p
                onClick={() => handleTab("5")}
                className="block link-primary text-decoration-underline fs-12 cursor-pointer m-0"
              >
                Ver agressores em mediação
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col xs={6} lg={4} className="d-flex align-self-stretch flex-fill">
          <Card className="card-animate mb-0 w-100">
            <CardBody>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="text-muted mb-0 widget-card-title">INATIVOS</p>
                  <h2 className="mt-3 mb-2 ff-secondary fw-semibold">
                    <span className="counter-value" data-target="3">
                      {widgets.inactiveAgressors.loading || panelLoading ? (
                        <Spinner color="primary" />
                      ) : (
                        <CountUp
                          start={0}
                          end={
                            !widgets.inactiveAgressors.error &&
                            widgets.inactiveAgressors.counter
                          }
                          duration={4}
                        />
                      )}
                    </span>
                  </h2>
                </div>
                <div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title rounded fs-3 bg-success-subtle">
                      <i className="bx bx-trophy text-success"></i>
                    </span>
                  </div>
                </div>
              </div>
              <p
                onClick={() => handleTab("6")}
                className="block link-primary text-decoration-underline fs-12 cursor-pointer m-0"
              >
                Ver agressores inativos
              </p>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};
