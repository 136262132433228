import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
} from "reactstrap";

//SimpleBar
import SimpleBar from "simplebar-react";
import { setSelectedCompany } from "../../store/actions";
import useDotsLoader from "../../hooks/useDotsLoader";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { getCompanyProductsByCompanyId } from "../../store/companyProducts/action";
import { dsVariables } from "../../DesignSystem/variables";

const CompaniesDropdown = ({ companies, selectedCompany }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const { selectedCampaign, user, event } = useSelector(state => {
    return {
      selectedCampaign: state.Profile.selectedCampaign,
      user: state.Profile.user,
      event: state.Mediation.event,
    }
  })

  const dots = useDotsLoader();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isCompaniesDropdown, setIsCompaniesDropdown] = useState(false);
  const [companyFilterByName, setCompanyFilterByName] = useState("");

  const toggleCompaniesDropdown = () => {
    setIsCompaniesDropdown(!isCompaniesDropdown);

    if (isCompaniesDropdown) {
      setCompanyFilterByName("");
    }
  };

  const handleSelectedCompanyFilter = (value) => {
    setCompanyFilterByName(value);
  };

  useEffect(() => {
    if (event?.customerId && user.companies) {
      const companyId = event.customerId
      dispatch(setSelectedCompany({
        value: event.customerId,
        label: user.companies?.find(el => el._id == companyId)?.name,
        category: user.companies?.find(el => el._id == companyId)?.category,
        isChildCompany: user.companies?.find(el => el._id == companyId)?.isChildCompany
      }))
    }
  }, [event]);

  useEffect(() => {
    setCompanyFilterByName("");
  }, [selectedCompany]);

  const filteredAndSortedCompanies =
    companies &&
    companies
      .slice()
      .sort((a, b) => {
        // A empresa selecionada fica no topo da lista.
        if (a.name === selectedCompany.label) return -1;
        if (b.name === selectedCompany.label) return 1;
        return a.name.localeCompare(b.name);
      })
      .filter(
        (item) =>
          !companyFilterByName ||
          new RegExp(companyFilterByName, "i").test(item.name)
      );

  const selectCompany = (e) => {
    const companyId = e.currentTarget.id;
    const companyName = e.currentTarget.textContent;

    searchParams.set('companyId', companyId);
    if (selectedCampaign) searchParams.set('campaignId', selectedCampaign.value);
    dispatch(
      setSelectedCompany({
        value: companyId,
        label: companyName,
        category: user.companies?.find(el => el._id == companyId)?.category,
        isChildCompany: user.companies?.find(el => el._id == companyId)?.isChildCompany
      })
    );
    dispatch(getCompanyProductsByCompanyId(companyId))

    setCompanyFilterByName("");

    navigate({ search: searchParams.toString() });
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isCompaniesDropdown}
        toggle={toggleCompaniesDropdown}
        className="ms-1 topbar-head-dropdown header-item"
      >
        <DropdownToggle
          style={{ color: dsVariables.colors.Astronaut[400], cursor: user.companies?.length == 1 ? 'default' : 'pointer' }}
          className="btn btn-default bg-transparent d-flex gap-2 align-items-center p-0 justify-content-end"
          tag="button"
          id="company-label"
        >
          <span>{selectedCompany?.label}</span>
          {user.companies?.length > 1 && <i className="bx bx-chevron-down fs-22"></i>}
        </DropdownToggle>
        {
          user.companies?.length > 1 &&
          <DropdownMenu className="notify-item language py-2 w-">
            <div className="py-3 px-3 border-top-0 border-start-0 border-end-0 border-dashed border w-300">
              <h6 className="mb-2 mt-0 fs-14 fw-semibold">Trocar conta</h6>
              <div>
                <div className="form-icon">
                  <Input
                    type="email"
                    className="form-control form-control-icon"
                    id="searchBrands"
                    placeholder="Pesquisar nome da empresa"
                    onChange={(event) =>
                      handleSelectedCompanyFilter(event.target.value)
                    }
                    value={companyFilterByName}
                  />
                  <i className="ri-search-line"></i>
                </div>
              </div>
            </div>

            <SimpleBar style={{ maxHeight: "260px" }}>
              {!filteredAndSortedCompanies ? (
                <div className="mt-3 d-flex align-items-center justify-content-center">
                  {t("global.loading")}
                  {dots}{" "}
                </div>
              ) : (
                filteredAndSortedCompanies?.map((company, idx) =>
                  company.name === selectedCompany.label ? (
                    <DropdownItem className="notify-item active" key={idx}>
                      <span className="align-middle">{company.name}</span>
                    </DropdownItem>
                  ) : (
                    <DropdownItem
                      className="notify-item"
                      key={idx}
                      id={company._id}
                      onClick={(e) => selectCompany(e)}
                    >
                      <span className="align-middle">{company.name}</span>
                    </DropdownItem>
                  )
                )
              )}
            </SimpleBar>
          </DropdownMenu>
        }
      </Dropdown>
    </React.Fragment>
  );
};

export default CompaniesDropdown;