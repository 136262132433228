export interface IAdminInitialState {
    companiesList: any[]
    companiesGroupList: any[]
    user:any
    users: any[]
}


export const AdminInitialState: IAdminInitialState = {
    companiesList: [],
    companiesGroupList: [],
    user: null,
    users: []
}
