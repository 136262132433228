import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Register from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import ResetPassword from "./auth/resetpwd/reducer";
import Panel from "./pages/panel/reducer";
import Report from "./pages/reports/reducer";
import Mediation from "./pages/mediation/reducer";
import AnalyticalReport from "./pages/analytical/reducer";
import Threats from "./threats/reducer";
import CompanyProducts from "./companyProducts/reducer"
import Admin from "./admin/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Register,
  ForgetPassword,
  ResetPassword,
  Profile,
  Panel,
  Report,
  Mediation,
  AnalyticalReport,
  Threats,
  CompanyProducts,
  Admin
});

export default rootReducer;
