import { useState,useEffect } from 'react';
import styled from 'styled-components';
import { dsVariables } from "../variables";

export type TSwitchState = 'default' | 'disabled';

export interface ISwitchProps {
  state?: TSwitchState;
  isOn?: boolean;
  onChange: (isOn: boolean) => void;
}

export default function Switch({ isOn = false, onChange, state = 'default' }: ISwitchProps) {
  const [checked, setChecked] = useState(isOn);

  useEffect(() => {
    setChecked(isOn)
  }, [isOn]);

  const handleToggle = () => {
    if (state !== 'disabled') {
      const newValue = !checked;
      setChecked(newValue);
      onChange(newValue);
    }
  };

  return (
    <SwitchWrapper isOn={checked} state={state} onClick={handleToggle}>
      <ToggleBall isOn={checked} state={state} />
    </SwitchWrapper>
  );
}

const SwitchWrapper = styled.div<{ isOn: boolean; state: TSwitchState }>`
  width: ${dsVariables.spacing[28]};
  height: ${dsVariables.spacing[16]};
  border-radius: ${dsVariables.spacing[8]};
  display: flex;
  align-items: center;
  padding: ${dsVariables.spacing[4]};;
  cursor: ${({ state }) => (state === 'disabled' ? 'not-allowed' : 'pointer')};
  transition: background-color 0.3s ease;

  background-color: ${({ isOn, state }) =>
    state === 'disabled'
      ? isOn
        ? dsVariables.colors.Astronaut[400]
        : 'none'
      : isOn
        ? dsVariables.colors.Astronaut[1000]
        : 'none'};  

  border: 1px solid ${({ isOn, state }) =>
    isOn
      ? 'none'
      : dsVariables.colors.Astronaut[200]};
`;

const ToggleBall = styled.div<{ isOn: boolean; state: TSwitchState; }>`
  width: ${dsVariables.spacing[8]};
  height: ${dsVariables.spacing[8]};
  border-radius: 50%;
  background-color: ${({ state, isOn }) =>
    state === 'disabled'
      ? dsVariables.colors.Gray[300]
      : isOn
        ? dsVariables.colors.Gray[50]
        : dsVariables.colors.Gray[400]}; 

  transition: transform 0.3s ease;
  transform: ${({ isOn }) => (isOn ? 'translateX(12px)' : 'translateX(2px)')};
`;