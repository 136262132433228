import { useSelector } from "react-redux"
import { IStore } from "../../interfaces/store.interface"
import { ActionEnum, ModuleEnum, userActionPermissions } from "../constants/user-action-permissions.constant";
import { useLocation } from "react-router-dom";

function getModuleByPath(path: string) {
    if (path.includes('manage-threats')) return ModuleEnum.frauds;
    if (path.includes('manage-takedown')) return ModuleEnum.frauds;
    if (path.includes('report')) return ModuleEnum.brand_bidding;
    if (path.includes('panel')) return ModuleEnum.brand_bidding;
    if (path.includes('admin')) return ModuleEnum.admin;
    return ModuleEnum.brand_bidding;
}

function getFunctionalityByAction(action: ActionEnum) {
    for (const [_, functionalities] of Object.entries(userActionPermissions)) {
        for (const [functionality, actions] of Object.entries(functionalities as any)) {
            for (const [_, value] of Object.entries(actions as any)) {
                if (value === action) {
                    return functionality
                }
            }
        }
    }
    return null;
}

export default function useCanPerformAction(action: ActionEnum) {
    const path = useLocation().pathname

    const actionModule = getModuleByPath(path)
    const actionFunctionality = getFunctionalityByAction(action)

    const { user, selectedCompany } = useSelector((store: IStore) => store.Profile)

    if (user.isCustomer) {
        return Boolean(user.roles[0].actionPermissions
            .find((el: any) =>
                el.module == actionModule &&
                el.functionality == actionFunctionality &&
                el.name == action))
    }

    return Boolean(user.roles.find((el: any) => el.category == "*" || selectedCompany?.isChildCompany || el.category == selectedCompany!.category)?.actionPermissions
        .find((el: any) =>
            el.module == actionModule &&
            el.functionality == actionFunctionality &&
            el.name == action))
}