import React from "react";
import { Navigate } from "react-router-dom";

//Panel
import Panel from "../pages/Pages/Panel";
import Reports from "../pages/Pages/Reports";
import TicketDetails from "../pages/Pages/Reports/TicketDetails";
import AnalyticalReport from "../pages/Pages/AnalyticalReport/AnalyticalReport";
import PrivacyPolicy from "../pages/Pages/Legal/PrivacyPolicy";
import TermsOfUse from "../pages/Pages/Legal/TermsOfUse";

//pages
import Starter from "../pages/Pages/Starter/Starter";
import Maintenance from "../pages/Pages/Maintenance/Maintenance";
import ComingSoon from "../pages/Pages/ComingSoon/ComingSoon";

//login
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPassword from "../pages/Authentication/ForgetPassword";
import PasswordCreate from "../pages/Authentication/PasswordCreate";

// User Profile
import UserProfile from "../pages/Authentication/user-profile";
import GoogleOAuthSuccessRedirect from "../pages/Authentication/GoogleOAuthSuccessRedirect";
import LookerStudioReport from "../pages/Pages/Reports/LookerStudioReport";
import DefaultError from "../pages/Pages/Errors/Default";

// 2FA
import TwoFactorAuthentication from '../pages/Authentication/TwoFactorAuthentication';
import ValidateTwoFactor from '../pages/Authentication/ValidateTwoFactor';
import FraudPanel from "../pages/Pages/FraudPanel";
import ManageThreats from "../DesignSystem/pages/ManageThreats";
import Admin from "../DesignSystem/pages/Admin";
import AwaitRole from "../DesignSystem/pages/AwaitRole";

const authProtectedRoutes = [
  { path: "/panel", component: <Panel /> },
  { path: "/index", component: <Panel /> },
  { path: "/reports", component: <Reports /> },
  { path: "/ticket-details", component: <TicketDetails /> },
  { path: "/analytical-report", component: <AnalyticalReport /> },

  // Fraud
  { path: "/frauds-panel", component: <FraudPanel /> },
  { path: "/manage-threats", component: <ManageThreats /> },

  // Admin
  { path: "/admin", component: <Admin /> },
  
  //Pages
  { path: "/pages-starter", component: <Starter /> },

  //User Profile
  { path: "/profile", component: <UserProfile /> },
  { path: "/2fa", component: <TwoFactorAuthentication /> },

  { path: "/lookerstudio", component: <LookerStudioReport /> },
  { path: "/error", component: <DefaultError /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/panel" />,
  },
  { path: "*", component: <Navigate to="/panel" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/password-reset-email", component: <ForgetPassword /> },
  { path: "/password-reset", component: <PasswordCreate /> },
  { path: "/password-create", component: <PasswordCreate /> },
  { path: "/privacy-policy", component: <PrivacyPolicy /> },
  { path: "/terms-of-use", component: <TermsOfUse /> },
  { path: "/await-role", component: <AwaitRole /> },
  {
    path: "/google-redirect-success/:accessToken",
    component: <GoogleOAuthSuccessRedirect />,
  },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/login" />,
  },

  //AuthenticationInner pages
  { path: "/pages-maintenance", component: <Maintenance /> },
  { path: "/pages-coming-soon", component: <ComingSoon /> },
];

export { authProtectedRoutes, publicRoutes };
