import logoDark from "../../../assets/images/transition_branddi_dark_transparent.svg";

export default function AwaitRole() {
    return (
        <main className="w-100 vh-100 d-flex justify-content-center align-items-center bg-info position-relative">
            <section className="container d-flex flex-column gap-4 align-items-center">
                <img className="img-fluid" width={300} src={logoDark} alt="" height="100" />
                <h1 className="text-center text-white">
                    Você ainda não possui uma função atribuída no sistema.
                    Por favor, entre em contato com os administradores para que
                    possam configurá-la para você.
                </h1>
            </section>
            <a href="/logout" className="position-absolute top-0 end-0 text-black text-decoration-underline m-4 fs-4 fw-medium">Logout</a>
        </main>
    )
}