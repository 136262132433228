import withRouter from "../../Components/Common/withRouter";
import { Container, Card, Row, Col } from "reactstrap";

// Import Images
import loginLogoutBg from "../../DesignSystem/assets/login-logout.webp";
import loginLogoutMobileBg from "../../DesignSystem/assets/login-logout-mobile.webp";
import logoDark from '../../assets/images/transition_branddi_dark_transparent.svg'
import { dsVariables } from "../../DesignSystem/variables";

const ParticlesAuth = ({ children }) => {
  return (
    <div className="auth-page-wrapper min-vh-100">
      <div
        className="auth-one-bg-position auth-one-bg min-vh-100"
        id="auth-particles"
      >
        <Container className="d-flex flex-column min-vh-100 justify-content-center">
          <div className="d-flex gap-lg-3 flex-lg-row flex-column justify-content-center">
            <div className="w-lg-50 w-100 position-relative">
              <div style={{ color: dsVariables.colors.Gray[800], lineHeight: '18px' }} className="position-absolute bottom-0 end-0 d-lg-flex d-none flex-column gap-2 m-4">
                <div className="p-2 px-3 bg-white rounded-4 d-flex gap-3 align-items-center">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.47933 2.25001L5.33333 4.93268L3.18733 2.25001C3.10066 2.14286 2.98296 2.06515 2.85039 2.02752C2.71781 1.98989 2.57684 1.99419 2.4468 2.03982C2.31676 2.08546 2.20401 2.17019 2.12403 2.28242C2.04404 2.39464 2.00071 2.52886 2 2.66667V12.6667C2 13.402 2.598 14 3.33333 14H12.6667C13.402 14 14 13.402 14 12.6667V2.66667C13.9993 2.52886 13.956 2.39464 13.876 2.28242C13.796 2.17019 13.6832 2.08546 13.5532 2.03982C13.4232 1.99419 13.2822 1.98989 13.1496 2.02752C13.017 2.06515 12.8993 2.14286 12.8127 2.25001L10.6667 4.93268L8.52067 2.25001C8.26667 1.93401 7.73333 1.93401 7.47933 2.25001ZM3.33333 12.6667V11.3333H12.6673V12.6667H3.33333ZM10.146 6.41668C10.4 6.73334 10.934 6.73334 11.188 6.41668L12.6667 4.56734L12.6673 10H3.33333V4.56734L4.81267 6.41668C5.06667 6.73334 5.60067 6.73334 5.85467 6.41668L8 3.73401L10.146 6.41668Z" fill="#2a60e6" />
                  </svg>
                  <span>Monitore e notifique<br />concorrentes desleais</span>
                </div>
                <div className="p-2 px-3 bg-white rounded-4 d-flex gap-3 align-items-center">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.47933 2.25001L5.33333 4.93268L3.18733 2.25001C3.10066 2.14286 2.98296 2.06515 2.85039 2.02752C2.71781 1.98989 2.57684 1.99419 2.4468 2.03982C2.31676 2.08546 2.20401 2.17019 2.12403 2.28242C2.04404 2.39464 2.00071 2.52886 2 2.66667V12.6667C2 13.402 2.598 14 3.33333 14H12.6667C13.402 14 14 13.402 14 12.6667V2.66667C13.9993 2.52886 13.956 2.39464 13.876 2.28242C13.796 2.17019 13.6832 2.08546 13.5532 2.03982C13.4232 1.99419 13.2822 1.98989 13.1496 2.02752C13.017 2.06515 12.8993 2.14286 12.8127 2.25001L10.6667 4.93268L8.52067 2.25001C8.26667 1.93401 7.73333 1.93401 7.47933 2.25001ZM3.33333 12.6667V11.3333H12.6673V12.6667H3.33333ZM10.146 6.41668C10.4 6.73334 10.934 6.73334 11.188 6.41668L12.6667 4.56734L12.6673 10H3.33333V4.56734L4.81267 6.41668C5.06667 6.73334 5.60067 6.73334 5.85467 6.41668L8 3.73401L10.146 6.41668Z" fill="#2a60e6" />
                  </svg>
                  <span>Identifique e remova Golpes<br />Online envolvendo a sua<br />marca</span>
                </div>
                <div className="p-2 px-3 bg-white rounded-4 d-flex gap-3 align-items-center">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.47933 2.25001L5.33333 4.93268L3.18733 2.25001C3.10066 2.14286 2.98296 2.06515 2.85039 2.02752C2.71781 1.98989 2.57684 1.99419 2.4468 2.03982C2.31676 2.08546 2.20401 2.17019 2.12403 2.28242C2.04404 2.39464 2.00071 2.52886 2 2.66667V12.6667C2 13.402 2.598 14 3.33333 14H12.6667C13.402 14 14 13.402 14 12.6667V2.66667C13.9993 2.52886 13.956 2.39464 13.876 2.28242C13.796 2.17019 13.6832 2.08546 13.5532 2.03982C13.4232 1.99419 13.2822 1.98989 13.1496 2.02752C13.017 2.06515 12.8993 2.14286 12.8127 2.25001L10.6667 4.93268L8.52067 2.25001C8.26667 1.93401 7.73333 1.93401 7.47933 2.25001ZM3.33333 12.6667V11.3333H12.6673V12.6667H3.33333ZM10.146 6.41668C10.4 6.73334 10.934 6.73334 11.188 6.41668L12.6667 4.56734L12.6673 10H3.33333V4.56734L4.81267 6.41668C5.06667 6.73334 5.60067 6.73334 5.85467 6.41668L8 3.73401L10.146 6.41668Z" fill="#2a60e6" />
                  </svg>
                  <span>Remova produtos falsificados<br />em marketplaces. </span>
                </div>
                <div className="p-2 px-3 bg-white rounded-4 d-flex gap-3 align-items-center">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.47933 2.25001L5.33333 4.93268L3.18733 2.25001C3.10066 2.14286 2.98296 2.06515 2.85039 2.02752C2.71781 1.98989 2.57684 1.99419 2.4468 2.03982C2.31676 2.08546 2.20401 2.17019 2.12403 2.28242C2.04404 2.39464 2.00071 2.52886 2 2.66667V12.6667C2 13.402 2.598 14 3.33333 14H12.6667C13.402 14 14 13.402 14 12.6667V2.66667C13.9993 2.52886 13.956 2.39464 13.876 2.28242C13.796 2.17019 13.6832 2.08546 13.5532 2.03982C13.4232 1.99419 13.2822 1.98989 13.1496 2.02752C13.017 2.06515 12.8993 2.14286 12.8127 2.25001L10.6667 4.93268L8.52067 2.25001C8.26667 1.93401 7.73333 1.93401 7.47933 2.25001ZM3.33333 12.6667V11.3333H12.6673V12.6667H3.33333ZM10.146 6.41668C10.4 6.73334 10.934 6.73334 11.188 6.41668L12.6667 4.56734L12.6673 10H3.33333V4.56734L4.81267 6.41668C5.06667 6.73334 5.60067 6.73334 5.85467 6.41668L8 3.73401L10.146 6.41668Z" fill="#2a60e6" />
                  </svg>
                  <span>Não deixe seus clientes<br />caírem nas garras de<br />aproveitadores.</span>
                </div>
              </div>
              <img width={160} className="position-absolute top-0 end-0 d-lg-block d-none m-4 pe-2 pt-1" src={logoDark} />
              <img className="w-100 h-100 object-fit-fill d-lg-block d-none" src={loginLogoutBg} />
              <img className="w-100 object-fit-fill d-block d-lg-none rounded-top" src={loginLogoutMobileBg} />
            </div>
            {children}
          </div>
        </Container>

        <footer className="position-absolute bottom-0 w-100 d-flex justify-content-center mb-5" >
          <Container className="d-flex justify-content-between">
            <div style={{ color: 'rgba(255,255,255,0.5)' }} className="text-center">
              <p className="mb-0">
                &copy; {new Date().getFullYear()} BRANDMONITOR SERVICOS
                ONLINE LTDA
              </p>
            </div>

            <div className="d-flex gap-3">
              <a className="text-decoration-underline" style={{ color: 'rgba(255,255,255,0.5)' }} href="https://branddi.com/privacy-policy">Privacidade</a>
              <a className="text-decoration-underline" style={{ color: 'rgba(255,255,255,0.5)' }} href="https://branddi.com/terms-of-service">Termos de Uso</a>
            </div>
          </Container>
        </footer>
      </div>
    </div >
  );
};

export default withRouter(ParticlesAuth);
