import React, { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { localStorageKeys } from "../config/localStorageKeys";
import { resetProfileFlag, setUserProfile } from "../store/actions";

const NonProtected = (props) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const checkUserToken = useCallback(() => {
    const accessToken = localStorage.getItem(localStorageKeys.AUTH_USER);

    if (!accessToken) {
      return false;
    } else {
      return true;
    }
  }, [])


  const selectProfileState = (state) => state.Profile;
  const { userLoggedIn } = useSelector(selectProfileState);

  useEffect(() => {

    const isTokenValid = checkUserToken();
    if (isTokenValid && location.pathname !== "/logout" && location.pathname !== "/error"
      && location.pathname !== "/privacy-policy" && location.pathname !== "/terms-of-use"
    ) {
      navigate("/panel")
    }

    if (!isTokenValid && userLoggedIn) {
      dispatch(resetProfileFlag())
    }

  }, [checkUserToken, dispatch, navigate, userLoggedIn, location]);



  return <>{(!userLoggedIn || location.pathname === "/logout" || location.pathname === "/privacy-policy" || location.pathname === "/terms-of-use" || location.pathname === "/error") && props.children}</>;

};

export { NonProtected };