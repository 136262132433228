/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Label, Collapse } from "reactstrap";
import Select from "react-select";
import { Line } from "./MixedCharts";
import { Widgets } from "./Widgets";
import { TopReferrals } from "./TopReferrals";
import { useDispatch, useSelector } from "react-redux";
import {
  getAgressorsEliminated,
  getAgressorsInactive,
  getAgressorsFound,
  getAgressorsMediation,
  getAgressorsNew,
  getAgressorsNotified,
  getAgressorsReappeared,
  getBrandScore,
  getLineAgressors,
  getTopAggressors,
  getTotalEliminated,
  getUserCampaigns,
  setSelectedCampaign,
} from "../../../store/actions";
import { useTranslation } from "react-i18next";
import CampaignSelector from "../../../Components/Common/CampaignSelector";
import * as moment from "moment-timezone";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "../../../DesignSystem/components/Modal";
import Body from "../../../DesignSystem/components/Text/Body";
import { ActionEnum } from "../../../DesignSystem/constants/user-action-permissions.constant";
import useCanPerformAction from "../../../DesignSystem/hooks/CanPerformAction.hook";

const Starter = () => {
  document.title = "Branddi.com - Painel de BrandBidding";
  document.description =
    "Combata o uso indevido da sua marca e maximize seus resultados";

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation()
  const navigate = useNavigate()

  const firstRenderOfKpis = useRef(true);
  const firstRenderOfBrandScore = useRef(true);
  const firstRenderOfTotalEliminated = useRef(true);

  const selectProfileState = (state) => state.Profile;
  const { user, selectedCampaign, selectedCompany } =
    useSelector(selectProfileState);

  const canAccessPanel = useCanPerformAction(ActionEnum.everything)

  const selectPanelState = (state) => state.Panel;
  const {
    widgets,
    panelLoading,
    line,
    lineLoading,
    lineError,
    topAggressors,
    topAggressorsLoading,
    topAggressorsError,
    brandScore,
    brandScoreLoading,
    totalEliminated,
    brandScoreError,
    totalEliminatedLoading,
    panelCampaignId,
  } = useSelector(selectPanelState);

  const [lineSelectedChannel, setLineSelectedChannel] = useState("search");
  const [lineSelectedPeriod, setLineSelectedPeriod] = useState(14);
  const [topAggressorsSelectedPeriod, setTopAggressorsSelectedPeriod] =
    useState(14);
  const [showModalFraudCta, setShowModalFraudCta] = useState(false);

  const [todayMinusOne, setTodayMinusOne] = useState(() => {
    const endDate = moment();
    endDate.subtract(1, "days").endOf("day").tz("America/Sao_Paulo");
    return endDate.format();
  });

  const [widgetsStartDate, setWidgetsStartDate] = useState(() => {
    const startDate = moment();
    startDate.subtract(14, "days").startOf("day").tz("America/Sao_Paulo");
    return startDate.format();
  });

  const [lineStartDate, setLineStartDate] = useState(() => {
    const startDate = moment();
    startDate
      .subtract(lineSelectedPeriod || 14, "days")
      .startOf("day")
      .tz("America/Sao_Paulo");
    return startDate.format();
  });

  const [topAggressorsStartDate, setTopAggressorsStartDate] = useState(() => {
    const startDate = moment();
    startDate
      .subtract(topAggressorsSelectedPeriod || 14, "days")
      .startOf("day")
      .tz("America/Sao_Paulo");
    return startDate.format();
  });

  const handleLineSelectedChannelChange = (value) => {
    setLineSelectedChannel(value);
  };

  const handleLineSelectedPeriodChange = (value) => {
    setLineSelectedPeriod(value);
  };

  const handleTopAggressorsSelectedPeriod = (value) => {
    setTopAggressorsSelectedPeriod(value);
  };

  useEffect(() => {
    if (!selectedCampaign.value || !topAggressorsStartDate) {
      return;
    }

    dispatch(
      getTopAggressors(
        selectedCampaign.value,
        topAggressorsStartDate,
        todayMinusOne,
        10,
        1,
        "search"
      )
    );
  }, [dispatch, selectedCampaign.value, topAggressorsStartDate, todayMinusOne]);

  useEffect(() => {
    if (!selectedCampaign.value) {
      return;
    }

    let startDate = moment();
    const campaignCreation = moment(selectedCampaign.createdAt).tz(
      "America/Sao_Paulo"
    );

    if (lineSelectedPeriod === 0) {
      startDate = campaignCreation;
    } else {
      startDate.subtract(lineSelectedPeriod, "days");
      if (startDate.isBefore(campaignCreation)) {
        startDate = campaignCreation;
      }
    }

    startDate.startOf("day").tz("America/Sao_Paulo");
    setLineStartDate(startDate.format());
  }, [lineSelectedPeriod, selectedCampaign.value]);

  useEffect(() => {
    if (!selectedCampaign.value || !lineStartDate) {
      return;
    }

    dispatch(
      getLineAgressors(
        selectedCampaign.value,
        lineStartDate,
        todayMinusOne,
        lineSelectedChannel
      )
    );
  }, [
    dispatch,
    selectedCampaign.value,
    lineStartDate,
    todayMinusOne,
    lineSelectedChannel,
  ]);

  useEffect(() => {
    if (!selectedCampaign.value) {
      return;
    }

    if (firstRenderOfBrandScore.current) {
      (brandScore === null || panelCampaignId !== selectedCampaign.value) &&
        dispatch(getBrandScore(selectedCampaign.value));
      firstRenderOfBrandScore.current = false;
    } else {
      dispatch(getBrandScore(selectedCampaign.value));
    }
  }, [todayMinusOne, dispatch, selectedCampaign]);

  useEffect(() => {
    if (!selectedCampaign.value) {
      return;
    }

    if (firstRenderOfTotalEliminated.current) {
      (totalEliminated === null ||
        panelCampaignId !== selectedCampaign.value) &&
        dispatch(
          getTotalEliminated(
            selectedCampaign.value,
            moment(selectedCampaign.createdAt).tz("America/Sao_Paulo").format(),
            todayMinusOne
          )
        );
      firstRenderOfTotalEliminated.current = false;
    } else {
      dispatch(
        getTotalEliminated(
          selectedCampaign.value,
          moment(selectedCampaign.createdAt).tz("America/Sao_Paulo").format(),
          todayMinusOne
        )
      );
    }
  }, [todayMinusOne, dispatch, selectedCampaign]);

  useEffect(() => {
    if (!selectedCampaign.value) {
      return;
    }

    let startDate = moment();
    const campaignCreation = moment(selectedCampaign.createdAt).tz(
      "America/Sao_Paulo"
    );

    if (topAggressorsSelectedPeriod === 0) {
      startDate = campaignCreation;
    } else {
      startDate.subtract(topAggressorsSelectedPeriod, "days");
      if (startDate.isBefore(campaignCreation)) {
        startDate = campaignCreation;
      }
    }

    startDate.startOf("day").tz("America/Sao_Paulo");
    setTopAggressorsStartDate(startDate.format());
  }, [topAggressorsSelectedPeriod, selectedCampaign.value]);

  useEffect(() => {
    if (!selectedCampaign.value) {
      return;
    }
    if (firstRenderOfKpis.current) {
      (widgets.foundAgressors.counter === null ||
        panelCampaignId !== selectedCampaign.value) &&
        dispatch(
          getAgressorsFound(
            selectedCampaign.value,
            widgetsStartDate,
            todayMinusOne
          )
        );
      (widgets.newAgressors.counter === null ||
        panelCampaignId !== selectedCampaign.value) &&
        dispatch(
          getAgressorsNew(
            selectedCampaign.value,
            widgetsStartDate,
            todayMinusOne
          )
        );
      (widgets.notifiedAgressors.counter === null ||
        panelCampaignId !== selectedCampaign.value) &&
        dispatch(
          getAgressorsNotified(
            selectedCampaign.value,
            widgetsStartDate,
            todayMinusOne
          )
        );
      (widgets.inMediationAgressors.counter === null ||
        panelCampaignId !== selectedCampaign.value) &&
        dispatch(
          getAgressorsMediation(
            selectedCampaign.value,
            widgetsStartDate,
            todayMinusOne
          )
        );
      (widgets.eliminatedAgressors.counter === null ||
        panelCampaignId !== selectedCampaign.value) &&
        dispatch(
          getAgressorsEliminated(
            selectedCampaign.value,
            widgetsStartDate,
            todayMinusOne
          )
        );
      (widgets.inactiveAgressors.counter === null ||
        panelCampaignId !== selectedCampaign.value) &&
        dispatch(
          getAgressorsInactive(
            selectedCampaign.value,
            widgetsStartDate,
            todayMinusOne
          )
        );
      (widgets.reappearedAgressors.counter === null ||
        panelCampaignId !== selectedCampaign.value) &&
        dispatch(
          getAgressorsReappeared(
            selectedCampaign.value,
            widgetsStartDate,
            todayMinusOne
          )
        );
      firstRenderOfKpis.current = false;
    } else {
      dispatch(
        getAgressorsFound(
          selectedCampaign.value,
          widgetsStartDate,
          todayMinusOne
        )
      );
      dispatch(
        getAgressorsNew(selectedCampaign.value, widgetsStartDate, todayMinusOne)
      );
      dispatch(
        getAgressorsNotified(
          selectedCampaign.value,
          widgetsStartDate,
          todayMinusOne
        )
      );
      dispatch(
        getAgressorsMediation(
          selectedCampaign.value,
          widgetsStartDate,
          todayMinusOne
        )
      );
      dispatch(
        getAgressorsEliminated(
          selectedCampaign.value,
          widgetsStartDate,
          todayMinusOne
        )
      );
      dispatch(
        getAgressorsInactive(
          selectedCampaign.value,
          widgetsStartDate,
          todayMinusOne
        )
      );
      dispatch(
        getAgressorsReappeared(
          selectedCampaign.value,
          widgetsStartDate,
          todayMinusOne
        )
      );
    }
  }, [selectedCampaign, dispatch, widgetsStartDate, todayMinusOne]);

  useEffect(() => {
    if (location.search.includes('showModalFraudCta')) setShowModalFraudCta(true)
    else setShowModalFraudCta(false)
  }, [location]);

  if (!canAccessPanel) return <p>Vc não tem permissão para acessar essa funcionalidade</p>
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <CampaignSelector
            panelLoading={panelLoading}
            user={user}
            selectedCampaign={selectedCampaign}
            selectedCompany={selectedCompany}
          />
          <Row>
            <Col md={12} lg={7}>
              <Widgets
                selectedCampaign={selectedCampaign}
                selectedCompany={selectedCompany}
                widgets={widgets}
                panelLoading={panelLoading}
                brandScore={brandScore}
                brandScoreLoading={brandScoreLoading}
                totalEliminated={totalEliminated}
                totalEliminatedLoading={totalEliminatedLoading}
              />
            </Col>

            <Col md={12} lg={5} className="d-flex align-self-stretch flex-fill">
              <TopReferrals
                onSelectedPeriodChange={handleTopAggressorsSelectedPeriod}
                selectedPeriod={topAggressorsSelectedPeriod}
                topAggressors={topAggressors}
                topAggressorsLoading={topAggressorsLoading}
                panelLoading={panelLoading}
                topAggressorsError={topAggressorsError}
                brandScoreError={brandScoreError}
                selectedCampaign={selectedCampaign}
                selectedCompany={selectedCompany}
              />
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Line
                dataColors='["--vz-secondary", "--vz-danger"]'
                onSelectedChannelChange={handleLineSelectedChannelChange}
                onSelectedPeriodChange={handleLineSelectedPeriodChange}
                selectedChannel={lineSelectedChannel}
                selectedPeriod={lineSelectedPeriod}
                line={line}
                lineLoading={lineLoading}
                panelLoading={panelLoading}
                lineError={lineError}
              />
            </Col>
          </Row>
        </Container>
      </div>
      {
        <Modal width={480} isOpen={showModalFraudCta} onClose={() => navigate('/panel')} title="Contratar Fraudes Digitais" subtitle="Você não tem acesso ao módulo.">
          <br />
          <Body style="medium">Tenha controle sobre as Fraudes que afetam sua empresa.</Body>
          <br />
          <div className="mt-2"></div>
          <Body>
            Contate um dos nossos atendentes o quanto antes, para que você possa controlar, monitorar e
            <Body style="medium">&nbsp;Eliminar&nbsp;</Body>
            as fraudes que
            <Body style="medium">&nbsp;roubam&nbsp;</Body>
            seus lucros!
          </Body>
          <br />
          <br />

          <p>
            <Body style="medium">Entre em contato com seu atendimento!</Body>
          </p>
          <br />
        </Modal>
      }
    </React.Fragment>
  );
};

export default Starter;
