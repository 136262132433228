import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import { IStore } from "../../../interfaces/store.interface";
import { Form, FormControl, FormLabel, FormSelect } from "react-bootstrap";
import { FormGroup } from "reactstrap";
import { useEffect, useState } from "react";
import { changeUserPermissions, fetchUsers, getAllCompaniesToAdmin, getCompaniesGroup, getUserToChangePermissions, inviteUser } from "../../../store/admin/action";
import ButtonPrimary from "../../components/Button/ButtonPrimary";
import { RolesEnum } from "../../../enums/roles.enum";
import { setLoading } from "../../../store/threats/actions";
import useCanPerformAction from "../../hooks/CanPerformAction.hook";
import { ActionEnum } from "../../constants/user-action-permissions.constant";
import { useNavigate } from "react-router-dom";
import CompaniesDropdownAdmin from "./companies-dropdown-admin";
import { Bounce, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { permissionCategory } from "../../constants/permission-category.constant";
import UserDropdownAdmin from "./user-dropdown-admin";
import InviteForm from "./forms/invite-form";
import ChangePermissionForm from "./forms/change-permission-form";

export default function Admin() {
    const navigate = useNavigate()

    const canEdit = useCanPerformAction(ActionEnum.edit_permissions)
    const canView = useCanPerformAction(ActionEnum.view_permissions)
    const canInviteOperator = useCanPerformAction(ActionEnum.invite_agents)
    const canInviteCustomer = useCanPerformAction(ActionEnum.invite_customers)

    const canAccessPage = canEdit || canView || canInviteOperator || canInviteCustomer

    if (!canAccessPage) navigate('/')

    const dispatch = useDispatch()

    const { isLoading, companies, companiesGroup, user, currentUser, users } = useSelector((store: IStore) => ({
        isLoading: store.Threats.isLoading,
        companies: store.Admin.companiesList,
        companiesGroup: store.Admin.companiesGroupList,
        user: store.Admin.user,
        currentUser: store.Profile.user,
        users: store.Admin.users,
    }))

    const [isInvite, setIsInvite] = useState(true);

    return (
        <div className="page-content container-fluid my-md-5">
            {isLoading && <Loader />}

            <div className="d-flex gap-2 mt-md-5">
                <ButtonPrimary state={!isInvite ? 'default' : 'disabled'} label="Convidar" onClick={() => setIsInvite(true)} />
                {canEdit && <ButtonPrimary state={isInvite ? 'default' : 'disabled'} label="Trocar permissão" onClick={() => setIsInvite(false)} />}
            </div>

            {
                isInvite
                    ? <InviteForm />
                    : <ChangePermissionForm />
            }
            <ToastContainer />
        </div >
    )
}