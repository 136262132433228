import { PermissionCategoryTextEnum, PermissionCategoryValueEnum } from "../../enums/permission-category.enum";

export const permissionCategory = {
    BLACK_1: {
        text: PermissionCategoryTextEnum.BLACK_1,
        value: PermissionCategoryValueEnum.BLACK_1,
    },
    BLACK_2: {
        text: PermissionCategoryTextEnum.BLACK_2,
        value: PermissionCategoryValueEnum.BLACK_2,
    },
    TITANIUM_1: {
        text: PermissionCategoryTextEnum.TITANIUM_1,
        value: PermissionCategoryValueEnum.TITANIUM_1,
    },
    TITANIUM_2: {
        text: PermissionCategoryTextEnum.TITANIUM_2,
        value: PermissionCategoryValueEnum.TITANIUM_2,
    },
    PLATINUM_1: {
        text: PermissionCategoryTextEnum.PLATINUM_1,
        value: PermissionCategoryValueEnum.PLATINUM_1,
    },
    PLATINUM_2: {
        text: PermissionCategoryTextEnum.PLATINUM_2,
        value: PermissionCategoryValueEnum.PLATINUM_2,
    },
    EVERYTHING: {
        text: PermissionCategoryTextEnum.EVERYTHING,
        value: PermissionCategoryValueEnum.EVERYTHING,
    },
}