/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Input,
  Label,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
  Fade,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { loginUser, resetLoginFlag, resetPanel } from "../../store/actions";
import withRouter from "../../Components/Common/withRouter";
import { api } from "../../config";
import { dsVariables } from "../../DesignSystem/variables";

const Login = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectProfileState = (state) => state.Profile;
  const { user } = useSelector(selectProfileState);

  const [passwordShow, setPasswordShow] = useState(false);

  const selectLoginState = (state) => state.Login;

  const { loginLoading, loginError, loginErrorMessage } =
    useSelector(selectLoginState);

  const validation = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(t("page.authentication.login.rule.email-required"))
        .email(t("page.authentication.login.rule.email-valid")),

      password: Yup.string().required(
        t("page.authentication.login.rule.password-required")
      ),
    }),

    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate));
    },
  });

  const googleLogin = () => {
    window.location = `${api.API_URL}/auth/google`;
  };

  useEffect(() => {
    if (loginError) {
      setTimeout(() => {
        dispatch(resetLoginFlag());
      }, 4000);
    }
  }, [dispatch, loginError]);

  useEffect(() => {
    dispatch(resetPanel());
  }, [dispatch]);

  document.title = "Branddi.com - Login";

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="w-lg-50" style={{ backgroundColor: dsVariables.colors.Gray[50], borderTopRightRadius: dsVariables.cornerRadius[4], borderBottomRightRadius: dsVariables.cornerRadius[4] }}>
          <div className="p-3">
            <div className="mb-4">
              <h4 className="text-dark fw-semibold">
                {t("page.authentication.login.title")}
              </h4>
              <p className="text-muted">
                {t("page.authentication.login.subtitle")}
              </p>
            </div>

            {loginError && (
              <Alert color="danger">
                {loginErrorMessage || (
                  <Fade>
                    {" "}
                    {t(
                      "page.authentication.login.rule.email-or-password-invalid"
                    )}
                  </Fade>
                )}
              </Alert>
            )}

            <div className="p-2 mt-4">
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                }}
              >
                <div className="mb-4">
                  <Label htmlFor="email" className="form-label">
                    {t("page.authentication.login.label.email")}
                  </Label>
                  <Input
                    name="email"
                    className="form-control"
                    placeholder={t(
                      "page.authentication.login.placeholder.email"
                    )}
                    type="email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>

                <div >
                  <Label className="form-label" htmlFor="password-input">
                    {t("page.authentication.login.label.senha")}
                  </Label>
                  <div className="position-relative auth-pass-inputgroup mb-3">
                    <Input
                      name="password"
                      type={passwordShow ? "text" : "password"}
                      className="form-control pe-5"
                      placeholder={t(
                        "page.authentication.login.placeholder.password"
                      )}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.password &&
                          validation.errors.password
                          ? true
                          : false
                      }
                    />
                    {validation.touched.password &&
                      validation.errors.password ? (
                      <FormFeedback type="invalid">
                        {validation.errors.password}
                      </FormFeedback>
                    ) : null}
                    {validation.errors.password &&
                      validation.touched.password ? null : (
                      <button
                        onClick={() => setPasswordShow(!passwordShow)}
                        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                        type="button"
                        id="password-addon"
                      >
                        <i className="ri-eye-fill align-middle"></i>
                      </button>
                    )}
                  </div>
                </div>

                <div className="d-flex justify-content-between my-4">
                  <div className="form-check">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="auth-remember-check"
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="auth-remember-check"
                    >
                      {t("page.authentication.login.keep-conected")}
                    </Label>
                  </div>
                  <div className="float-end">
                    <Link to="/password-reset-email" className="text-muted">
                      {t("page.authentication.login.label.forget-password")}
                    </Link>
                  </div>
                </div>

                <div >
                  <Button
                    disabled={loginError ? null : loginLoading ? true : false}
                    color="secondary"
                    className="btn btn-secondary w-100 d-flex justify-content-center align-items-center"
                    type="submit"
                    style={{ backgroundColor: dsVariables.colors.Astronaut[700] }}
                  >
                    {loginError ? null : loginLoading ? (
                      <Spinner size="sm" className="me-2" />
                    ) : null}
                    {t("page.authentication.login.btn.sign-in")}
                  </Button>
                </div>

                <div className="mt-4 text-center">
                  <div className="signin-other-title">
                    <h5 className="fs-13 mb-4 title">
                      {t("page.authentication.login.sign-in-with")}
                    </h5>
                  </div>
                  <div>
                    <Link
                      to="#"
                      className="btn btn-danger btn-icon me-1"
                      onClick={(e) => {
                        e.preventDefault();
                        googleLogin();
                      }}
                    >
                      <i className="ri-google-fill fs-16" />
                    </Link>
                  </div>
                </div>
              </Form>
            </div>

          </div>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(Login);
