import styled from "styled-components"
import { dsVariables } from "../variables"
import Icon from "./Icon"
import { SvgIconComponent } from "@mui/icons-material"

interface IProps {
    state?: "default" | "disabled"
    onChange: any
    required?: boolean
    options: any[]
    selected?: any
    width?: number
    error?: boolean
}

export default function Select({ state = 'default', onChange, required = false, options, selected, width, error = false }: IProps) {

    return (
        <SelectStyled state={state} error={error} width={width} disabled={state == 'disabled'} required={required} onChange={onChange} className="border-0 w-100 " value={selected}  >
            {options.map((el, index) => <option key={index} value={el}>{el}</option>)}
        </SelectStyled>
    )
}

const SelectStyled = styled.select<{ iconLeft?: SvgIconComponent, width?: number, error?: boolean,state?: "default" | "disabled" }>`
    height: ${dsVariables.spacing[36]};   
    ${props => props.width && `
        width: ${props.width}px !important;
    `}
    padding: ${dsVariables.spacing[8]} ${dsVariables.spacing[8]};
    gap: ${dsVariables.spacing[8]};
    align-self: stretch;
    color: ${props => props.state == 'disabled' ? dsVariables.colors.Gray[300]:dsVariables.colors.Gray[700]};
    border-radius: ${dsVariables.cornerRadius[4]};
    border: 1px solid ${props =>
        props.error ? dsVariables.colors.Red[400]
            : props.state == 'disabled'
                ? dsVariables.colors.Gray[100]
                : dsVariables.colors.Gray[400]
    } !important;
`