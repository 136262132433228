import { ThreatDaysEnum } from "../../enums/threat-days.enum";
import { ThreatPlatformEnum } from "../../enums/threat-platform.enum";
import { ThreatStatusActiveEnum } from "../../enums/threat-status-active.enum";
import { ThreatTypeEnum } from "../../enums/threat-type.enum";
import { IThreatAdsForm } from "../../interfaces/threat-ads-form.interface";
import { IThreatContentForm } from "../../interfaces/threat-content-form.interface";
import { IThreatProfileForm } from "../../interfaces/threat-profile-form.interface";
import { IThreatSiteForm } from "../../interfaces/threat-site-form.interface";
import { IThreatsRegister } from "../../interfaces/threats-register.interface";
import { IThreatsTable } from "../../interfaces/threats-table.interface";
import { IThreatsUpdate } from "../../interfaces/threats-update.interface";
import { IThreats } from "../../interfaces/threats.interface";
import { ActionTypesEnum } from "./types.enum";

export const submitForm = (formData: any) => {
  return {
    type: ActionTypesEnum.SUBMIT_FORM,
    payload: formData,
  };
};

export const submitFormSuccess = () => {
  return {
    type: ActionTypesEnum.SUBMIT_FORM_SUCCESS,
  };
};

export const submitFormError = (error: any) => {
  return {
    type: ActionTypesEnum.SUBMIT_FORM_ERROR,
    payload: error,
  };
};

export const changeFilterText = (text: string) => ({
  type: ActionTypesEnum.CHANGE_FILTER_TEXT,
  payload: text,
});

export const changeFilterType = (threatType: ThreatTypeEnum) => ({
  type: ActionTypesEnum.CHANGE_FILTER_TYPE,
  payload: threatType,
});

export const changeFilterPlatform = (platform: ThreatPlatformEnum) => ({
  type: ActionTypesEnum.CHANGE_FILTER_PLATFORM,
  payload: platform,
});

export const changeFilterStatus = (status: boolean) => ({
  type: ActionTypesEnum.CHANGE_STATUS_PLATFORM,
  payload: status,
});

export const changeFilterIPF = (ipf: number | string) => ({
  type: ActionTypesEnum.CHANGE_FILTER_IPF,
  payload: ipf,
});

export const changeFilterDateRangeStart = (startDate: Date) => ({
  type: ActionTypesEnum.CHANGE_FILTER_DATE_RANGE_START,
  payload: startDate,
});

export const changeFilterDateRangeEnd = (endDate: Date) => ({
  type: ActionTypesEnum.CHANGE_FILTER_DATE_RANGE_END,
  payload: endDate,
});

export const changeFilterDays = (days: ThreatDaysEnum) => ({
  type: ActionTypesEnum.CHANGE_FILTER_DAYS,
  payload: days,
});

export const changeThreatsList = (list: IThreats[]) => ({
  type: ActionTypesEnum.CHANGE_LIST,
  payload: list
})

export const changeThreatsTable = (table: IThreatsTable[]) => ({
  type: ActionTypesEnum.CHANGE_TABLE,
  payload: table
})

export const changeThreatsCurrentIds = (ids: string[]) => ({
  type: ActionTypesEnum.CHANGE_CURRENT_IDS,
  payload: ids
})

export const changeThreatsCurrent = (threat: IThreats) => ({
  type: ActionTypesEnum.CHANGE_CURRENT,
  payload: threat
})

export const changeSiteFormEvidenceLink = (link: string) => ({
  type: ActionTypesEnum.CHANGE_SITE_FORM_EVIDENCE_LINK,
  payload: link,
});

export const changeSiteFormEvidenceFiles = (files: File[]) => ({
  type: ActionTypesEnum.CHANGE_SITE_FORM_EVIDENCE_FILES,
  payload: files,
});

export const changeSiteFormType = (threatType: ThreatTypeEnum) => ({
  type: ActionTypesEnum.CHANGE_SITE_FORM_TYPE,
  payload: threatType,
});

export const changeSiteFormUrl = (url: string) => ({
  type: ActionTypesEnum.CHANGE_SITE_FORM_URL,
  payload: url,
});

export const changeSiteFormSearchDate = (searchDate: Date) => ({
  type: ActionTypesEnum.CHANGE_SITE_FORM_SEARCH_DATE,
  payload: searchDate,
});

export const changeSiteFormIsActive = (isActive: boolean) => ({
  type: ActionTypesEnum.CHANGE_SITE_FORM_IS_ACTIVE,
  payload: isActive,
});
// Profile Form Actions
export const changeProfileFormType = (threatType: ThreatTypeEnum) => ({
  type: ActionTypesEnum.CHANGE_PROFILE_FORM_TYPE,
  payload: threatType,
});

export const changeProfileFormUrl = (url: string) => ({
  type: ActionTypesEnum.CHANGE_PROFILE_FORM_URL,
  payload: url,
});

export const changeProfileFormSearchDate = (searchDate: Date) => ({
  type: ActionTypesEnum.CHANGE_PROFILE_FORM_SEARCH_DATE,
  payload: searchDate,
});

export const changeProfileFormEvidenceLink = (link: string) => ({
  type: ActionTypesEnum.CHANGE_PROFILE_FORM_EVIDENCE_LINK,
  payload: link,
});

export const changeProfileFormEvidenceFiles = (files: File[]) => ({
  type: ActionTypesEnum.CHANGE_PROFILE_FORM_EVIDENCE_FILES,
  payload: files,
});

export const changeProfileFormProfile = (profile: string) => ({
  type: ActionTypesEnum.CHANGE_PROFILE_FORM_PROFILE,
  payload: profile,
});

export const changeProfileFormPlatform = (platform: ThreatPlatformEnum) => ({
  type: ActionTypesEnum.CHANGE_PROFILE_FORM_PLATFORM,
  payload: platform,
});

export const changeProfileFormIsActive = (isActive: boolean) => ({
  type: ActionTypesEnum.CHANGE_PROFILE_FORM_IS_ACTIVE,
  payload: isActive,
});
// Content Form Actions
export const changeContentFormEvidenceLink = (link: string) => ({
  type: ActionTypesEnum.CHANGE_CONTENT_FORM_EVIDENCE_LINK,
  payload: link,
});

export const changeContentFormEvidenceFiles = (files: File[]) => ({
  type: ActionTypesEnum.CHANGE_CONTENT_FORM_EVIDENCE_FILES,
  payload: files,
});

export const changeContentFormType = (threatType: ThreatTypeEnum) => ({
  type: ActionTypesEnum.CHANGE_CONTENT_FORM_TYPE,
  payload: threatType,
});

export const changeContentFormUrl = (url: string) => ({
  type: ActionTypesEnum.CHANGE_CONTENT_FORM_URL,
  payload: url,
});

export const changeContentFormProfile = (profile: string) => ({
  type: ActionTypesEnum.CHANGE_CONTENT_FORM_CONTENT,
  payload: profile,
});

export const changeContentFormPlatform = (platform: ThreatPlatformEnum) => ({
  type: ActionTypesEnum.CHANGE_CONTENT_FORM_PLATFORM,
  payload: platform,
});

export const changeContentFormIsActive = (isActive: boolean) => ({
  type: ActionTypesEnum.CHANGE_CONTENT_FORM_IS_ACTIVE,
  payload: isActive,
});
// Ads Form Actions
export const changeAdsFormEvidenceLink = (link: string) => ({
  type: ActionTypesEnum.CHANGE_ADS_FORM_EVIDENCE_LINK,
  payload: link,
});

export const changeAdsFormEvidenceFiles = (files: File[]) => ({
  type: ActionTypesEnum.CHANGE_ADS_FORM_EVIDENCE_FILES,
  payload: files,
});

export const changeAdsFormType = (threatType: ThreatTypeEnum) => ({
  type: ActionTypesEnum.CHANGE_ADS_FORM_TYPE,
  payload: threatType,
});

export const changeAdsFormStartDate = (startDate: Date) => ({
  type: ActionTypesEnum.CHANGE_ADS_FORM_START_DATE,
  payload: startDate,
});

export const changeAdsFormSearchDate = (searchDate: Date) => ({
  type: ActionTypesEnum.CHANGE_ADS_FORM_SEARCH_DATE,
  payload: searchDate,
});

export const changeAdsFormId = (id: string) => ({
  type: ActionTypesEnum.CHANGE_ADS_FORM_ID,
  payload: id,
});

export const changeAdsFormPlatform = (platform: ThreatPlatformEnum) => ({
  type: ActionTypesEnum.CHANGE_ADS_FORM_PLATFORM,
  payload: platform,
});

export const changeAdsFormUrl = (url: string) => ({
  type: ActionTypesEnum.CHANGE_ADS_FORM_URL,
  payload: url,
});

export const changeAdsFormInstagramProfile = (instagramProfile: string) => ({
  type: ActionTypesEnum.CHANGE_ADS_FORM_INSTAGRAM_PROFILE,
  payload: instagramProfile,
});

export const changeAdsFormAdvertiserName = (advertiserName: string) => ({
  type: ActionTypesEnum.CHANGE_ADS_FORM_ADVERTISER_NAME,
  payload: advertiserName,
});

export const changeAdsFormAdvertiserId = (advertiserId: string) => ({
  type: ActionTypesEnum.CHANGE_ADS_FORM_ADVERTISER_ID,
  payload: advertiserId,
});

export const changeAdsFormIsActive = (isActive: boolean) => ({
  type: ActionTypesEnum.CHANGE_ADS_FORM_IS_ACTIVE,
  payload: isActive,
});

export const getFraudsByCompanyId = (getFraudsDto: any) => ({
  type: ActionTypesEnum.GET_FRAUDS_BY_COMPANY,
  payload: getFraudsDto,
});

export const registerFraud = (fraudRegister: IThreatsRegister) => ({
  type: ActionTypesEnum.REGISTER_FRAUD,
  payload: fraudRegister,
});

export const registerFraudFailure = (errorMessage: string) => ({
  type: ActionTypesEnum.REGISTER_FRAUD_FAILURE,
  payload: errorMessage,
});

export const addFraud = (newFraud: IThreats) => ({
  type: ActionTypesEnum.ADD_FRAUD,
  payload: newFraud,
});

export const updateFraud = (updatedFraud: IThreats) => ({
  type: ActionTypesEnum.UPDATE_FRAUD,
  payload: updatedFraud,
});

export const updateMultipleFrauds = (updatedFrauds: IThreats[]) => ({
  type: ActionTypesEnum.UPDATE_MULTIPLE_FRAUDS,
  payload: updatedFrauds,
});

export const restoreFraud = (fraud: IThreats) => ({
  type: ActionTypesEnum.RESTORE_FRAUD,
  payload: fraud,
});

export const restoreMultipleFrauds = (frauds: IThreats[]) => ({
  type: ActionTypesEnum.RESTORE_MULTIPLE_FRAUDS,
  payload: frauds,
});

export const updateFraudFromSaga = (updatedFraud: IThreats) => ({
  type: ActionTypesEnum.UPDATE_FRAUD_FROM_SAGA,
  payload: updatedFraud,
});

export const updateMultipleFraudsFromSaga = (updatedFrauds: IThreats[]) => ({
  type: ActionTypesEnum.UPDATE_MULTIPLE_FRAUDS_FROM_SAGA,
  payload: updatedFrauds,
});

export const cleanForms = () => ({
  type: ActionTypesEnum.CLEAN_FORMS,
});

export const setLoading = (isLoading: boolean) => ({
  type: ActionTypesEnum.IS_LOADING,
  payload: isLoading,
});

export const changeFraudsQuantityByState = (fraudsQuantityByState: any) => ({
  type: ActionTypesEnum.CHANGE_FRAUDS_QUANTITY_BY_STATE,
  payload: fraudsQuantityByState,
})

export const changeFraudsFilteredQuantityByState = (fraudsQuantityByState: any) => ({
  type: ActionTypesEnum.CHANGE_FRAUDS_FILTERED_QUANTITY_BY_STATE,
  payload: fraudsQuantityByState,
})

export const changeFraudsTotalPageByState = (fraudsTotalPagesByState: any) => ({
  type: ActionTypesEnum.CHANGE_FRAUDS_TOTAL_PAGES_BY_STATE,
  payload: fraudsTotalPagesByState,
})

export const changeFraudsFormError = (error: any) => ({
  type: ActionTypesEnum.CHANGE_FORM_ERROR,
  payload: error,
})

export const changeSiteForm = (form: IThreatSiteForm) => ({
  type: ActionTypesEnum.CHANGE_SITE_FORM,
  payload: form,
})

export const changeAdsForm = (form: IThreatAdsForm) => ({
  type: ActionTypesEnum.CHANGE_ADS_FORM,
  payload: form,
})

export const changeProfileForm = (form: IThreatProfileForm) => ({
  type: ActionTypesEnum.CHANGE_PROFILE_FORM,
  payload: form,
})

export const changeContentForm = (form: IThreatContentForm) => ({
  type: ActionTypesEnum.CHANGE_CONTENT_FORM,
  payload: form,
})