import { CompanyProductsInitialState } from "./state";
import { ActionTypesEnum } from "./types.enum";

const companyProductsReducer = (state = CompanyProductsInitialState, action: { type: ActionTypesEnum, payload: any }) => {
    switch (action.type) {
        case ActionTypesEnum.FETCH_COMPANY_PRODUCTS:
            return {
                ...state,
            };

        case ActionTypesEnum.CHANGE_COMPANY_PRODUCTS:
            return {
                ...state,
                companyId: action.payload.companyId,
                products: action.payload.products
            };

        default:
            return state;
    }
};
export default companyProductsReducer;