import React, { useMemo } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import TableContainer from "../../../../Components/Common/TableContainerReactTable";
import { formatDateWithMoment } from "../../../../utils/dateFormat";
import BouncingDotsLoader from "../../../../Components/Common/BoucingDotsLoader";
import moment from "moment";
import "moment/locale/pt-br";

const TicketDetailsModalAdverts = (props) => {
  const formatDateWithMoment = (date) => {
    return moment(date).format('DD [de] MMM, YYYY');
  };
  const handleToggle = () => props.toggle(false);
  const advertsData = props.ads;
  const loading = props.loading;
  const error = props.error;
  const columns = useMemo(
    () => [
      {
        Header: "Anúncios",
        accessor: (cellProps) => {
          const decodedString = decodeURIComponent(cellProps._id.match);
          const url = new URL(decodedString);
          const displayString =
            url.pathname && url.pathname !== "/"
              ? url.pathname + url.search + url.hash
              : url.hostname;
          return (
            <div title={decodedString}>
              {displayString.length > 15
                ? displayString.substring(0, 15) + "..."
                : displayString}
            </div>
          );
        },
      },
      {
        Header: "Data",
        accessor: (cellProps) => {
          return <div>{formatDateWithMoment(cellProps.createdAt)}</div>;
        },
      },
      {
        Header: "Local",
        accessor: (cellProps) => {
          const locationParts = cellProps.geoLocation?.split(",");
          const city = locationParts[0];
          return <div>{city}</div>;
        },
      },
      {
        Header: "Device",
        accessor: (cellProps) => {
          return <div>{cellProps.device}</div>;
        },
      },
      {
        Header: "Canal",
        accessor: (cellProps) => {
          return <div>{cellProps.channel}</div>;
        },
      },
      {
        Header: "Palavra chave",
        accessor: (cellProps) => {
          return <div>{cellProps.keyword}</div>;
        },
      },
      {
        Header: "Página",
        accessor: (cellProps) => {
          return <div className="text-center">{cellProps.page}</div>;
        },
      },
      {
        Header: "Posição",
        accessor: (cellProps) => {
          return <div className="text-center">{cellProps.position}</div>;
        },
      },
      {
        Header: "Evidência",
        accessor: (cellProps) => {
          return (
            <div>
              <a
                href={cellProps.htmlDataUrl || cellProps.domain}
                target="_blank"
                className="link-secondary text-decoration-underline cursor-pointer"
                rel="noreferrer"
              >
                <i className="bx bx-file-find fs-18 align-bottom me-1"></i>
              </a>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <Modal isOpen={props.isOpen} toggle={handleToggle} centered size="lg">
        <ModalHeader className="text-center">Anúncios</ModalHeader>
        {loading ? (
          <div
            style={{ minHeight: "30vh" }}
            className="d-flex align-items-center justify-content-center"
          >
            <BouncingDotsLoader />
          </div>
        ) : error ? (
          <div
            style={{ minHeight: "30vh" }}
            className="d-flex align-items-center justify-content-center flex-column"
          >
            <h3>Erro ao carregar anúncios.</h3>
            <p> Por favor, entre em contato com nossa equipe de suporte.</p>
          </div>
        ) : (
          <ModalBody>
            <TableContainer
              columns={columns}
              data={advertsData}
              customPageSize={10}
              className="custom-header-css table align-middle table-nowrap"
              tableClassName="table-centered align-middle table-nowrap mb-0"
              thClass="text-black"
              divClass="table-responsive"
              theadClass="table-light"
              onRowClick={() => {}}
            />
          </ModalBody>
        )}
      </Modal>
    </>
  );
};

export default TicketDetailsModalAdverts;
