import { useSelector } from "react-redux"
import { IStore } from "../../interfaces/store.interface"

export default function useIsEnabledOrTrial(product: 'brand bidding' | 'fraud') {
    const { products } = useSelector((store: IStore) => store.CompanyProducts)

    const currentDate = new Date()

    const isTrial = products?.find(el => el.productName == product)?.trial && (new Date(products?.find(el => el.productName == product)?.trialDateEnd || new Date())) > currentDate

    const isEnabled = products?.find(el => el.productName == product)?.enabled

    const atLeastOne = isTrial || isEnabled

    return { atLeastOne, isTrial }
}