import { takeLatest, call, put } from "redux-saga/effects";
import {
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_EVENT_FAILURE,
  PATCH_EVENT_MEDIATION_REQUEST,
  PATCH_EVENT_MEDIATION_SUCCESS,
  PATCH_EVENT_MEDIATION_FAILURE,
  PATCH_EVENT_EVIDENCE_UPLOAD_REQUEST,
  PATCH_EVENT_EVIDENCE_UPLOAD_SUCCESS,
  PATCH_EVENT_EVIDENCE_UPLOAD_FAILURE,
  GET_EVENT_ADS_REQUEST,
  GET_EVENT_ADS_SUCCESS,
  GET_EVENT_ADS_FAILURE,
  DELETE_EVENT_EVIDENCE_REQUEST,
  DELETE_EVENT_EVIDENCE_SUCCESS,
  DELETE_EVENT_EVIDENCE_FAILURE,
} from "./actionTypes";
import {
  getEventAds,
  getEventById,
  updateEventMediation,
  uploadEventEvidence,
  deleteEventEvidence,
} from "../../../helpers/backend_helper";

function* getEventByIdSaga({ payload: { eventId } }) {
  try {
    const eventData = yield call(getEventById, eventId);
    yield put({ type: GET_EVENT_SUCCESS, payload: eventData });
  } catch (error) {
    yield put({ type: GET_EVENT_FAILURE, payload: error });

  }
}

function* getEventAdsSaga({ payload: { campaignId, domain } }) {
  try {
    const eventData = yield call(getEventAds, { campaignId, domain });
    yield put({ type: GET_EVENT_ADS_SUCCESS, payload: eventData });
  } catch (error) {
    yield put({ type: GET_EVENT_ADS_FAILURE, payload: error });
  }
}

function* updateEventMediationSaga(action) {
  try {
    const { eventId, data } = action.payload;

    yield call(updateEventMediation, eventId, data);

    yield put({ type: PATCH_EVENT_MEDIATION_SUCCESS });
  } catch (error) {
    yield put({ type: PATCH_EVENT_MEDIATION_FAILURE, error: error });
  }
}

function* uploadEventEvidenceSaga(action) {
  try {
    const { eventId, data } = action.payload;
    yield call(uploadEventEvidence, eventId, data);

    yield put({ type: PATCH_EVENT_EVIDENCE_UPLOAD_SUCCESS });
  } catch (error) {
    yield put({
      type: PATCH_EVENT_EVIDENCE_UPLOAD_FAILURE,
      error: error,
    });
  }
}

function* deleteEventEvidenceSaga(action) {
  try {
    const { eventId } = action.payload;
    const config = {};
    yield call(deleteEventEvidence, eventId, config);

    yield put({ type: DELETE_EVENT_EVIDENCE_SUCCESS });
  } catch (error) {
    yield put({ type: DELETE_EVENT_EVIDENCE_FAILURE, error: error });
  }
}

export default function* eventSaga() {
  yield takeLatest(GET_EVENT_REQUEST, getEventByIdSaga);
  yield takeLatest(GET_EVENT_ADS_REQUEST, getEventAdsSaga);
  yield takeLatest(PATCH_EVENT_MEDIATION_REQUEST, updateEventMediationSaga);
  yield takeLatest(
    PATCH_EVENT_EVIDENCE_UPLOAD_REQUEST,
    uploadEventEvidenceSaga
  );
  yield takeLatest(DELETE_EVENT_EVIDENCE_REQUEST, deleteEventEvidenceSaga);
}
