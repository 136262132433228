import {
  call,
  put,
  take,
  takeEvery,
  takeLatest,
  select,
} from "redux-saga/effects";

import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { loginError, loginSuccess } from "./actions";

import { postLogin } from "../../../helpers/backend_helper";
import { localStorageKeys } from "../../../config/localStorageKeys";
import {
  setUserLoggedIn,
  resetProfileFlag,
  setUserProfile,
} from "../profile/actions";

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLogin, {
      email: user.email,
      password: user.password,
    });

    localStorage.setItem(localStorageKeys.AUTH_USER, response.access_token);

    yield put(setUserProfile());
    yield take(setUserLoggedIn);

    yield put(loginSuccess());

    const updatedUser = yield select((state) => state.Profile.user);

    if (updatedUser.secret_2fa) {
      localStorage.setItem('token', updatedUser.secret_2fa);
      localStorage.setItem('2fa', true);
    }
    history("/panel");

  } catch {
    yield put(loginError());
  }
}

function* logoutUser() {
  try {
    localStorage.removeItem(localStorageKeys.AUTH_USER);
    localStorage.removeItem('token');
    localStorage.removeItem('2fa');
    yield put(resetProfileFlag());
  } catch (error) {
    yield put(loginError());
  }
}

function* socialLogin({ payload: { accessToken, history } }) {
  try {
    localStorage.setItem(localStorageKeys.AUTH_USER, accessToken);

    yield put(setUserProfile());
    yield take(setUserLoggedIn);

    yield put(loginSuccess());
    history("/dashboard");
  } catch (error) {
    yield put(loginError());
  }
}

function* authSaga() {
  yield takeLatest(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
