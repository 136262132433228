import { ICompanyProductsInitialState } from "./state";
import { ActionTypesEnum } from "./types.enum";

export const getCompanyProductsByCompanyId = (companyId: string) => ({
    type: ActionTypesEnum.FETCH_COMPANY_PRODUCTS,
    payload: companyId,
  });

export const changeCompanyProducts = (companyProducts: ICompanyProductsInitialState) => ({
    type: ActionTypesEnum.CHANGE_COMPANY_PRODUCTS,
    payload: companyProducts,
  });