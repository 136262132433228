import styled from "styled-components"
import LoaderImage from '../assets/loader-logo-brand-monitor.png'

export default function Loader() {
    return (
        <Div className="position-fixed top-0 start-0 h-100 w-100 d-flex justify-content-center align-items-center">
            <img width={120} height={120} src={LoaderImage} />
        </Div>
    )
}

const Div = styled.div`
    background: #00000077;
    z-index: 99999;

    img {
        animation: rotation 1.3s infinite linear;

        @keyframes rotation {
            from {
                transform: rotate3d(0, 100, 0, 0deg);
            }
            to {
                transform: rotate3d(0, 100, 0, 360deg);
            }
        }
}
`